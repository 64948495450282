.frame-types {

  height: 100%;

  md-grid-list {
    min-height: 100%;
  }

  md-grid-tile {
    overflow: hidden;
  }

  .tile-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all .5s;
    background-position: center;
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      content: "";
      background: linear-gradient(transparent 0%, rgba(0,0,0,0.4) 40%, rgba(0,0,0,0.75) 75%, rgba(0,0,0,0.9) 100%);
      transition: all .5s;
    }
  }

  md-grid-tile:hover .tile-background {
    transform: scale(1.2);
    background-blend-mode: multiply;
    background-color: #585452;

    &:after {
      opacity: 1;
    }
  }

  .preview-pane {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
  }

  .discover-pane {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    background: #f6f6f6;
    z-index: 2;

    .header {
      padding: 45px 32px;

      h1 {
        margin: 0 0 .5rem;
        font-size: 24px;
        font-weight: 700;
        color: #373648;
      }

      h2 {
        margin: 0;
        font-size: 16px;
        font-weight: 700;
        color: #888;
      }
    }
  }

  .discover-pane,
  .preview-pane {
    &.ng-enter,
    &.ng-leave {
      transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) .2s;
    }

    &.ng-enter,
    &.ng-leave.ng-leave-active {
      opacity: 0;
    }

    &.ng-enter.ng-enter-active,
    &.ng-leave {
      opacity: 1;
    }
  }
}
@media(max-width: 959px) {
  .frame-types {
    md-grid-tile .tile-background {
      &:after {
        opacity: 1;
      }
    }

    .preview-pane {
      display: none;
    }
  }
}
@media(min-width: 960px) {
  .frame-types {
    md-grid-tile .tile-background {
      &:after {
        opacity: .6;
      }
    }

    .frame-types {
      max-height: 100%;

      md-grid-list {
        max-height: 100%;
        padding-bottom: 0 !important;
      }
    }
  }
}
@media(min-width:640px) {
  .frame-types md-grid-list {
    // fixes md-grid-list bug which leaves the padding behind on resizing
    padding-bottom: 0 !important;
  }
}
@media(max-width:640px) {
  .frame-types .tile-background{
    // make darker on mobile since there is no hover affect
   // background-blend-mode: soft-light;
  //  background-color: #585452;
  }
}
