ftv-flow-bar {
  color: #fff;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
}

.store-flow-item {
  position: relative;
  display: flex;
  width: 100%;
  padding: 20px 16px;
  color: #eef0e5;
  flex-direction: row;
  background-color: currentColor;
  text-decoration: none;
  align-items: center;

  .store-flow-header {
    margin-bottom: 2px;
    font-size: 18px;
    font-weight: 500;
    color: #676968;
  }

  .store-flow-description {
    font-size: 12px;
    color: #676968;
    opacity: .75;
  }

  .store-flow-icon {
    display: flex;
    min-width: 30px;
    height: 30px;
    margin-right: 8px;
    color: white;
    flex-direction: row;
    background-color: #676968;
    border-radius: (30px / 2);

    align-items: center;
    justify-content: center;

    .store-flow-number {
      display: block;
    }

    .store-flow-check {
      display: none;
      width: 16px;
      height: 13px;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='13'><path fill='#fff' fill-rule='nonzero' d='M14.541.194a.66.66 0 0 0-.93 0L5.353 8.45a.66.66 0 0 1-.93 0L2.39 6.417a.66.66 0 0 0-.931 0L.212 7.665a.66.66 0 0 0 0 .93l4.211 4.211a.66.66 0 0 0 .931 0L15.788 2.372a.66.66 0 0 0 0-.931L14.541.194z'/></svg>");
    }
  }

  &:not(:first-child) {
    padding-left: 36px;
  }

  &:not(:last-child) {
    border-right: 2px solid white;

    &:after {
      position: absolute;
      right: -11px;
      z-index: 2;
      width: 0;
      height: 0;
      content: '';
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 12px solid currentColor;
    }

    &:before {
      position: absolute;
      right: -14px;
      z-index: 1;
      width: 0;
      height: 0;
      content: '';
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 12px solid white;
    }
  }

  &.is-active {
    color: #d76713;

    .store-flow-description,
    .store-flow-header {
      color: white;
    }

    .store-flow-icon {
      color: #d76713;
      background-color: #fff;
    }
  }

  &.is-done {
    color: #ddded5;

    .store-flow-icon {
      color: #fff;
      background-color: #219653;

      .store-flow-number {
        display: none;
      }

      .store-flow-check {
        display: block;
      }
    }

  }
}
