ftv-progress-bar{
    width: 100%;
}
.progress-bar {
    background-color:#7DAEF7 ;
    min-height:60px;
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    position: relative;
    .status{
        flex-wrap: wrap;;
        background-color: transparent;
        color: white;
        align-self: center;
        margin-left: 20px;
        z-index: 9999;
        display: flex;
        flex: 1 1 auto;
        font-size: 20px;
        line-height: 20px;
        align-items: center;
        .message {
            padding: 10px 0 10px 0;
        }
        .percent-complete {
            margin: 0 15px;
            color: #384652;
            flex: 1;
            padding: 10px 0 10px 0;
            .percentage{
                font-size: 25px;
                line-height: 25px;
            }
        }
        .do-not-close {
            margin-right: 30px;
            color: #d76713;
            padding: 10px 0 10px 0;
        }
    }
    .progress-status{
        background-color:#3763A9;
        height:100%;
        border-left: 5px solid #7DAEF7;
        position: absolute;
        z-index: 999;

    }
    .screen-block{
        background-color: #8e8e8e1f;
        z-index: 9;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}