.options-pane {

  .header-content {
    padding-right: 25px;
  }
  .md-headline{
    border: none;
  }
  .md-body-2 {
    border-top: 1px solid lightgray;
    padding-top: 10px;
  }

  .option-description {
    background: #676968;
    color: #fff;
    padding: 14px 14px 14px 60px;
    position: relative;

    &:before {
      position: absolute;
      display: block;
      background: #fff;
      color: #676968;
      width: 30px;
      height: 30px;
      top: 50%;
      margin-top: -15px;
      left: 14px;
      border-radius: 15px;
      content: 'i';
      text-align: center;
      line-height: 30px;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .vAccordion--ftv {
    v-pane {
      margin-bottom: 10px;
      background-color: white;
      border-bottom: 1px solid #979698;
    }
    v-pane-header {
      padding: 5px 15px;

      &:before {
        right: 35px;
      }
    }
  }
}
