.password-change {
  ftv-svg {
    float: left;
    padding: 10px;
    border: solid 2px #080;
    border-radius: 50%;
    margin-top: 0.67em;
    font-size: 34px;
    margin-right: 20px;
    width: 64px;
    box-sizing: border-box;
    text-align: center;

    path {
      fill: #080;
    }
  }
}
