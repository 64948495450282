.btn-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 10px;

  button {
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}

.ftv-button {
  text-transform: none;
  padding: 0 25px;

  &.md-primary {
    border: solid 1px #D76713;
  }
}

.md-button {
  text-transform: none;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  md-icon {
    margin: auto 5px;
  }

  &.small {
    min-height: 26px;
    min-width: 40px;
    font-size: 13px;
    line-height: 24px;
  }

  &.md-raised {
    background: #676968;
    color: #fff;

    &:not([disabled]) {
      box-shadow: none;

      &:hover {
        background: #676968;
      }
    }

    &.md-accent,
    &.md-accent:not([disabled]):hover {
      border: solid 1px #D76713;
      color: #D76713;
      background: transparent;
    }
  }

  .md-raised[disabled] {
    background: #BDBDBD;
    color: #fff;

    md-icon {
      color: #fff;
    }
  }

}
