ftv-loading-indicator {
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.2);
    z-index: 90000;
  }

  .popup {
    background: #fff;
    width: 200px;
    height: 200px;
    border-radius: 5px;
    box-shadow: 0 3px 3px 1px rgba(0,0,0,0.5);
  }
}
