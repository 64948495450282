ftv-save-design-component{
    overflow: auto;
    z-index: 900; // if over 999 and it covers popup dialog

}
ftv-save-design-step2 {
    .screen-block{
        position: absolute;
        top: 0;
        bottom: 0;
        background-color: #8a80805e;
        right: 0;
        left: 0;
    }
}
.save-design-step2 {
    display: flex;
    flex-wrap: wrap;
    background-color: #eae8df;
    font-weight: 500;
    font-weight: 500;
    color: #3c4e57;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    .close-btn {
        position: absolute;
        top: 2px!important;
        padding: 3px 7px!important;
        right: 3px!important;
        cursor: pointer;
        background-color: #d76713!important;
        border-radius: 11px!important;
        color: white;
        &:after{
            content: 'X' !important; /* ANSI X letter */
        }
    }
    .link-section{
        flex: 1 1 100%;
        // display: inline-block;
        width: 100%;
        background-color:white;
        .link-to-design{
            display:flex;
            flex: 1 1 auto;
            flex-wrap: wrap;
            .description{
                margin-right: 40px;
                flex-direction: column;
            }
            .config-id{
            }
        }
        .copy-link{
            .url {
                font-size: 12px;
                border-bottom: 4px solid darkgray;
                padding: 7px;
            }
            .md-button{
                display: inline-block;
                background-color:#384652;
            }
        }
    }
    .samples-and-discuss {
        display: flex;
        flex-wrap: wrap;
        .samples-section{
            background-color:white;
            display: inline-block;
        }
        .discuss-design-section{
            background-color:white;
            justify-content: space-around;
            border: 1px solid royalblue;
            h2 {
                flex: 0 0 100%;
            }
            /*
            flex: 1 1 31%;
            margin-left: 15px;
            margin:5px;
            .left-column, .right-column {
                width: 48%;
                display: inline-block;
            }
            */
            md-button {
                flex: 0 0 100%;
                align-self: center;
            }
        }
    }

}

@media only screen and (max-width: 599px) {
    ftv-save-design-step2 {
        .save-design-step2 {
            position: absolute;
            left: 0;
            bottom: 0;
            top: 0;
            right: 0;
            z-index:9;
            overflow-y: auto;
        }
    }
}