.mini-preview {
  position: relative;
  padding-bottom: 50%;
  overflow: hidden;
  width: 150px;

  img {
    position: absolute;
    margin-top: -17%;
    top: 0;
    height: auto !important;
    width: 100%;
  }
}

.config-library > .head {
  margin-bottom: 20px;

  h2 {
    margin: 0;
  }
}
