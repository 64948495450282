.account-settings {
  v-pane-header {
    background: #eee;
    &:before {
      margin-right: 10px;
      margin-top: -5px;
    }
    h3 {
      margin: 10px;
    }
  }

  md-content, md-tab-content {
    background: #fff;
  }

  h2 {
    margin: 0;
  }

  h3 {
    margin-bottom: 10px;
  }

  .button-size-label {
    padding-right: 15px;
  }

  .price-adjustment {
    width: 190px;
  }

  .logo {
    max-width: 250px;
  }

  .md-color-picker-input-container {
    width: 150px;
  }

  .size-slider {
    width: 250px;
  }

  .size-label {
    width: 70px;
  }

  fieldset {
    max-width: 100%;
  }

  .button-preview {

    height: 60px;

    button {
      border: none;
      margin-bottom: 2px;
      box-shadow: 0 3px 3px 1px rgba(0,0,0,0.5);
    }

    button:active {
      margin-top: 2px;
      margin-bottom: 0;
      box-shadow: 0 1px 2px rgba(0,0,0,0.5);
    }

    .size-0 {
      font-size: 1em;
      padding: 4px 8px;
      border-radius: 3px;
    }

    .size-1 {
      font-size: 1.5em;
      padding: 6px 14px;
      border-radius: 4px;
    }

    .size-2 {
      font-size: 2em;
      padding: 10px 24px;
      border-radius: 6px;
    }
  }

  .tab-preview {

    padding: 10px 0;
    overflow: hidden;

    .rotated {
      transform: rotate(-90deg);
      display: inline-block;

      &:after {
        content: "";
        float: left;
        margin-top: 100%;
      }
    }

    button {
      border: none;
      float: left;
      box-shadow: 0 3px 3px 1px rgba(0,0,0,0.5);
    }

    button:active {
      margin-top: 2px;
      margin-bottom: 0;
      box-shadow: 0 1px 2px rgba(0,0,0,0.5);
    }

    .size-0 {
      font-size: 1em;
      padding: 4px 8px;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .size-1 {
      font-size: 1.5em;
      padding: 6px 14px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .size-2 {
      font-size: 2em;
      padding: 10px 24px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  pre {
    overflow-x: auto;
    padding: 10px;
    background: #eee;
    user-select: text;
  }
  hr {
    border: none;
    border-top: solid 1px #aaa;
    margin: 20px 0;
  }

  .radio-container {
    margin-top: -20px;
  }

  .embed-tabs {
    border: 1px solid #e1e1e1;
    md-tab-content {
      background: rgb(246, 246, 246);
    }
  }
}

.preview-wrapper > h3 {
  margin-top: 0;
}

.store-preview {
  position: relative;
  width: 100%;

  .dummy {
    margin-top: 75%;
  }

  &.shadow {
    box-shadow: 0 4px 12px -2px #000;
  }

  .preview {
    position: absolute;
    border: solid 1px grey;
    border-radius: 4px;
    background-color: #edecec;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .header {
    height: 30px;
    overflow: hidden;

    > * {
      padding: 3px;
    }

    img {
      width: auto;
      display: inline-block;
      height: 100%;
    }
  }

  .section {
    background-color: #fff;
  }

  .tabs {
    height: 30px;
    border-bottom: solid 1px lightgray;

    div {
      float: left;
      font-size: 8px;
      height: 100%;

      span {
        padding: 4px;
        vertical-align: text-top;
      }
    }
  }

  .frame {
    border: solid 12px #bbb;
    background: #ddd;
    width: 70%;
    height: 40%;
    color: #fff;
  }

  .filler {
    background: #ddd;
    min-height: 20px;
  }

  .capture-form {
    position: absolute;
    left: 33%;
    right: 2%;
    bottom: 0;
    top: 60%;
    border: solid 1px lightgray;
    background-color: #fff;
    border-bottom: none;
  }
}

