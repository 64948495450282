ftv-save-design-component.ng-isolate-scope.layout-row.flex{
    padding: 0;
    margin: 0;
}
ftv-save-design-component #get-samples-wrapper {
    display:none;
}

@media only screen and (max-width: 599px) {
    ftv-save-design-component{
        position: absolute !important;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
    }
    .preview {
        margin: 0 auto;
    }
    .save-design-component {
        padding:0;
        position: relative;
        overflow-y: scroll;
        overflow-x: hidden;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        .save-design-context{
            position: relative;
        }
        .save-design-context h2 {
            font-size: 1.3em;
        }
        .save-design-context ul {
            font-size: .9em;
        }
        .save-design-form {
            padding: 0 !important;
            // flex: 1 1 100%;
            max-width: 50vw;
            width:50vw;
        }
        .preview {
            margin: 0;
            max-width: 50%!important;
            width: 50%;
            min-width: initial;
            margin-top: 8px;
        }
        .label {
            align-self: flex-start;
            padding-bottom: 10px;
        }
        .save-design-form .input-wrapper{
            flex: 1 1 auto;
            input {
                flex: 1;
            }
        }
        .btn-save{
            align-self: center;
        }
    }
    button.md-button.btn-get-samples{
        margin: 0 auto !important;
        font: size 12px;
    }
    .btn-save {
        margin: 4px !important;
    }
}
@media only screen and (min-width: 600px) {

    .save-design-form .input-wrapper{
        flex-wrap: wrap;
        input {
            max-width: 380px;
        }
    }

}
@media only screen and (min-width: 600px) and (max-width:959px) {
    .save-design-form .input-wrapper{
        flex-wrap: wrap;
        input {
            max-width: 380px;
        }
    }
    .save-design-component {
        .preview{
            flex:1 1 auto !important;
            margin: 0 auto;
        }
    }
    .save-design-component .save-design-form {
        padding: 0 !important;
        flex: 1 1 100%;
    }
}

.save-design-component {
    ftv-preview .preview-placeholder {
        max-width: 70%;
        max-height: 70%;
    }
    ftv-preview .image img {
        height: 150%;
    }
    background-color: #EBF4F7;
    border-left: 10px solid #A2CCD9;
    color: #364659;
    font-weight: 600;
    flex: 1 1 auto;
    align-items: center;
    box-sizing: border-box;
    display:flex;
    flex-wrap: wrap;;
    align-items: flex-start;
    position: relative;

    .label {
        align-self: flex-start;
    }
    .save-design-form {
        display: flex;
        flex: 1 1 33%;
        flex-direction: column;
        margin-top: 15px;
        .input-wrapper{
            display: flex;
            align-items: center;
            flex-direction: column;
            flex: 1 1 auto;
            align-content: flex-start;
            min-width: 0;
            max-width: 350px;
            width: 90%;
            input {
                font-size: 15px;
                height: fit-content;
                width: 100%;
                box-sizing: border-box;
                margin-right:10px;
            }
        }
    }
    .preview {
        min-width: 195px;
        flex: 1 1 33%;
        max-width: 180px;
    }
    .save-design-context{
        display: flex;
        flex: 1 1 33%;
        flex-direction: column;
        padding-right: 10px;
        h2{
            margin-bottom: 0;
            margin-top: 0;
            padding-left: 21px;
        }
    }
    .close-btn {
        position: absolute;
        top: 0;
        padding: 6px;
        right: 0;
        cursor: pointer;
        &:after{
            content: 'X'; /* ANSI X letter */
        }
    }

    form[name='save-design-form'] {
        align-items: center;
        // padding: 10px;
    }
    input {
        padding: 6px;
        margin-left: 10px;
    }
    .md-button {
        background-color: #384652 !important;
        padding: 0  !important;
        margin: 0  !important;
        border-radius: 15px  !important;
        flex: 0 1 auto;
        padding: 0px 10px !important;
        color: white !important;
        margin: 10px !important;
        align-self: flex-end;
    }
}

@media only screen and (max-width: 959px) {
    button.md-button.btn-get-samples{
        margin: 0 auto !important;
    }

 }