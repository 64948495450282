.md-toast-content.missing-option {
  background-color: #c79030 !important;
  color: black !important;
}

.content-wrapper {
  background-color: #eae8df;
}
@media (max-width: 350px) {
  button.md-primary.btn-save-design.md-button,
  button.md-primary.btn-get-samples.md-button {
    padding: 0 !important;
  }
  .btn-get-samples .get-label {
    display: none !important;
  }
}
@media (max-width: 600px) {
  button.md-primary.btn-save-design.md-button,
  button.md-primary.btn-get-samples.md-button {
    min-width: 0;
    width: 75px;
    max-width: 77px;
    padding: 0 5px;
    border: none;
    margin: 2px;
    font-size: 12px;
    min-width: 74px;
    font-weight: 600;
    border-radius: 2px;
    margin: 0 2px 0 0 !important;
    md-icon {
      margin: auto 0px;
    }

    .get-label {
      display: inline;
    }
  }

  .force-column-wrap {
    min-width: 300px !important;
    overflow-x: auto;
    margin: 2px;
  }
  [ui-view].tab-view {
    overflow: hidden;
  }
  .right-scroll-column {
    // min-height:4200px; /* required for safari*/
    overflow-y: visible;
  }
}

@media (min-width: 601px) {
  .right-scroll-column {
    overflow-y: visible;
    // min-height:3000px;
  }
}
@media (min-width: 960px) {
  .md-padding {
    padding: 0 16px;
  }
}

@media (max-width: 600px) {
  /* see save-design.components.ts for manual overrides */
  .config-column {
    //  position: absolute;
    top: 0;
    right: 0;
    margin-right: 0;
    // margin-top: 58vh; this is needed when the 'save my design' is open on narrow width
    // margin-top: 34vh;
    // padding-bottom: 34vh;
  }
  .preview-column-2 {
    margin-bottom: 10px !important;
    max-width: 100%;
  }
}

.preview-column {
  display: flex;
  flex: 1 1 auto;
  margin-bottom: 0;
  overflow: visible;
  z-index: 1;
}

/* see save-design.components.ts for manual overrides */
.config-column {
  flex: 1 1;
}
md-content.md-default-theme,
md-content {
  background-color: transparent;
}
.right-options-bar {
  overflow-y: scroll;
}
.md-headline {
  border-bottom: 1px solid lightgray;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #a99f80;
  padding-bottom: 4px;
  margin: 0;
}

.txt-btn {
  // color: #B27147;
  color: #b37147;
  justify-content: flex-end;
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
}
.view-all-btn-clmn {
  flex: 0 1 auto;
  padding: 0 !important;
  margin: 0;
  align-self: center;
  position: absolute;
  right: 0;
  top: 0;

  .md-button.view-all-btn {
    justify-content: center;
    flex-direction: column;
    background-color: #e9e9df;
    color: #b37147;
    line-height: 14px;
    padding: 4px 0;
    margin: 0;
    border: 1px solid lightgray;
    div {
      margin: 0;
      padding: 0;
    }
  }
}

md-icon.info-selection {
  // color: #304653;
  color: #314550;
}
md-icon.alert-unselected {
  height: 20px;
  color: #c79030;
  width: 20px;
  font-size: 4px;
  min-height: 20px;
  min-width: 20px;
  vertical-align: top;
}
.option-price {
  float: right;
}
//////////////////////////////////
[ui-view].tab-view {
  // remove full height of right hand option selection
  // height: 100% !important;

  &.ng-enter,
  &.ng-leave {
    transition: all 0.3s;
  }

  // Prevents a jump when switching tab
  &.ng-leave {
    margin-top: -16px;
  }
}

.tabs-pane {
  align-self: stretch;
  transition: width 0.5s;

  md-tab-content > div {
    height: 100%;

    > md-content {
      // remove full height of right hand options
      // height: 100%;

      > div {
        height: 100%;
      }
    }
  }
}
@media (max-width: 599px) {
  .preview {
    .zoom-icon {
      position: relative;
      right: 37px;
      height: 25px;
      width: 25px;
      top: 12px;
    }
  }
  .zoomedflex1 {
    flex-grow: 10 !important;
  }
  .zoomedflex2 {
    flex-grow: 30 !important;
  }
  .toggle-color-selector {
    right: 36px !important;
  }
  .color-bar {
    width: 320px;
    right: 10px !important;
    .title {
      margin: 0;
    }
  }
}
.preview {
  .zoomed .zoom-icon {
    // display: none;
    z-index: 9999;
    cursor: pointer;
  }

  .zoom-icon {
    position: absolute;
    right: 65px;
    bottom: 10px;
    height: 25px;
    width: 25px;
    z-index: 9999;
    cursor: pointer;
  }
}

.preview-header {
  padding: 8px;

  a.md-button {
    margin: 0;
  }
}

md-icon.green-check {
  color: green;
}

.preview-image-container {
  position: relative;
  flex: 1 0 !important;
  min-width: 0;
  overflow: visible;
  background: #f0ebdc;
  transition: background 1s;
  box-shadow: inset 0 0 60px -20px #000;
  min-height: 0;
  .config-name {
    z-index: 20;
    position: absolute;
    top: 5%;
    left: 0;
    right: 0;

    > div {
      background: #fff;
      padding: 10px;
      width: 250px;

      md-icon {
        opacity: 0.5;
      }

      &:hover md-icon {
        opacity: 1;
      }
    }

    input {
      border: none;
    }
  }
  @media (max-width: 791px) {
    .color-selector-wrapper {
      max-width: 100%;
    }
  }
  .color-selector-wrapper {
    overflow: visible;
    z-index: 899;
    position: relative;
    max-width: 100%;
    display: inline-flex;
    width: 100%;
    height:25px;
   // background-color:red;
    .toggle-color-selector {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 10px;
      bottom: 25px;
      cursor: pointer;
    }
    .color-bar {
      // overflow: visible;
      display: flex;
      color: white;
      background-color: #686967;
      // align-items: flex-start;
      // justify-content: flex-end;
      position: absolute;
      // top:-55px;
      border: 5px solid white;
      z-index: 9999;
      background-color: #686967;
      position: absolute;
      border: 5px solid white;
      min-width: 0;
      min-height: 0;
      bottom: 67px;
      right: 2px;
      overflow: visible;
      z-index: 999999;
      .title {
        margin-left: 15px;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: -19px;
        right: 3px;
        margin: 0 auto;
        width: 0;
        height: 0;
        border-top: 20px solid #686967;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
      }
      &:before {
        content: "";
        position: absolute;
        bottom: -27px;
        right: -1px;
        margin: 0 auto;
        width: 0;
        height: 0;
        border-top: 25px solid white;
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
      }
      .color-options-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        overflow: visible;
        .color-option {
          padding: 6px;
          background-color: white;
          margin: 10px;
          flex-grow: 0;
          cursor: pointer;
          .color {
            width: 50px;
            height: 30px;
          }
        }
        .color-option-space-hack {
          width: 62px;
          height: 0px;
          margin: 0 10px 0 10px;
        }
      }
    }
  }

  .add-to-cart-wrapper {
    z-index: 20;
    .column {
      margin: auto;
    }
  }
  .continue-btn {
    z-index: 20;
    position: absolute;
    bottom: 5%;
    left: 0;
    right: 0;

    button {
      min-width: 33%;
    }
  }

  .color-picker {
    position: absolute;
    z-index: 20;
    top: 50%;
    left: 0;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    width: 45px;
    height: 150px;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0 0 40px -10px #000;
    margin-top: -75px;

    .label {
      transform: rotate(90deg);
      white-space: nowrap;
      position: absolute;
      transform-origin: left;
      margin-right: -20px;
      right: -27px;
      top: 10px;
    }

    [md-color-picker] {
      bottom: 32px;
      margin-left: 5px;
      position: absolute;

      md-input-container {
        display: none;
      }
    }
  }
}

.design-type-popup {
  position: relative;

  md-list {
    position: absolute;
    width: 300px;
    background: #fff;
    z-index: 99;
  }
}

#previewPrice .price {
  position: relative;

  a {
    text-decoration: none;
    vertical-align: sub;
  }

  .price-details {
    position: absolute;
    padding: 15px;
    border: solid 1px darkgray;
    border-radius: 5px;
    background: #fff;
    min-width: 350px;
    bottom: calc(100% + 15px);
    z-index: 10;

    &::before {
      width: 0;
      height: 0;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-top: 11px solid darkgray;
      position: absolute;
      top: 100%;
      content: " ";
      left: 10px;
    }

    &::after {
      margin-left: 2px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #fff;
      position: absolute;
      top: 100%;
      content: " ";
      left: 10px;
    }
  }
}

.preview-details-mobile {
  display: flex;
  position: relative;
  margin: 0;
  background-color: #384652;
  padding: 3px 0;
  > div {
    padding: 0 10px;
    font-size: 0.8em;
    white-space: nowrap;
  }
}
@media (max-width: 599px) {
  .tabs-pane {
    width: auto;
  }
  .config-pane {
    justify-content: space-evenly;
  }
  .add-to-cart-wrapper.preview-details-mobile.hide-gt-xs.flex {
    //  flex-grow: 0;
    margin: 0;
    background-color: #eef0e5;
  }
  ftv-preview .image img {
   // -o-object-fit: cover;
   // object-fit: cover;
    // margin-top: 19px;
  }
  .summary > v-pane {
    background-color: white;
    padding: 15px;
    margin-bottom: 15px;
  }

  .summary .footer {
    padding-bottom: 20px;
  }
  .preview-image-container {
    // width: 100%;
    // .config-column{height: 27vh;
    transition: all 0.3s;
    .color-selector-wrapper .color-bar .color-options-wrapper .color-option .color {
      width: 30px;
      // height:30px;
    }
    ftv-preview div img.zoomed {
      // height: 25vh;
      width: 60%;
      margin: 0 auto;
      transition: all 0.3s;
    }
  }

  .preview-details {
    transition: opacity 0.2s ease-in-out 0.3s, width 0.3s;
    width: 60%;

    &.ng-hide {
      width: 0;
      opacity: 0;
      transition: opacity 0.2s, width 0.3s ease-in-out 0.3s;
    }
  }

  #previewPrice .price-details {
    bottom: auto !important;
    top: calc(100% + 15px);
    left: -50%;

    &::before {
      bottom: 100%;
      border-bottom: 11px solid darkgray;
      top: auto !important;
      border-top: none !important;
      left: 45px !important;
    }

    &::after {
      bottom: 100%;
      border-bottom: 10px solid #fff;
      top: auto !important;
      border-top: none !important;
      left: 45px !important;
    }
  }
}
@media (max-width: 959px) {
}
@media (min-width: 600px) and (max-width: 959px) {
  .tabs-pane {
    width: 50%;

    &.large {
      width: 70%;
    }
  }
}
@media (min-width: 960px) {
  .tabs-pane {
    width: 450px;

    &.large {
      width: 50%;
      min-width: 450px;
    }
  }
}
.config-pane {
  margin-top: 0px;
  min-height: 0;
  overflow: visible;
}
// .config-pane {
//   background: #fff;
//   box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
// }
@media (min-width: 600px) {
  .preview-image-container {
    // flex-shrink: 0;
    //   flex-basis: auto;
  }
}
@media (min-width: 960px) {
  .config-pane {
    md-tabs-wrapper {
      display: none;
    }

    md-tabs-content-wrapper {
      top: 0 !important;
    }
  }
}

#tab-content-3 {
  > div {
    height: 100%;

    > div {
      height: 100%;

      > div {
        height: 100%;
      }
    }
  }
}

.section-title {
  // color: #676968;
  color: #314550;
  font-size: 22px;
  font-weight: 600;
  &:after {
    content: "";
    width: 100%;
    border-bottom: 5px solid #979698;
    padding-bottom: 4px;
    position: relative;
    padding-top: 0;
    display: block;
  }
}

.design-button {
  min-height: 80px;
  position: relative;

  .md-button {
    margin: 0 20px;
  }
}

.item-summary {
  // min-height: 80px;
  position: relative;
  cursor: pointer;
  padding: 8px 0 0 8px;

  .thumb-container {
    margin: 0 -20px;
    width: 100px;
    height: 100%;
    margin-right: 10px;

    .thumb {
      width: auto;
      cursor: default;
      max-height: 80px;
    }
  }
}
.display-none {
  display: none;
}
@media (max-width: 599px) {
  .button-toggle {
    border-radius: 2px;
    margin: 0 2px 0 0 !important;
  }
  .button-toggled {
    background-color: #d4d4d4 !important;
    color: black !important;
  }
  ftv-get-samples-on-preview {
    display: flex;
    flex: 0 1 auto;
    justify-items: center;
    md-checkbox {
      margin-bottom: 4px;
    }
    #get-samples {
      background-color: transparent;
      .close-chevron {
        display: none;
      }
      .close-chevron.is-hidden {
        display: none;
      }
      .sample-body {
        z-index: 2;
        border-radius: 10px;
        padding: 3px;
        background-color: #d4d4d4;
        font-size: 20px;
        div .refund-message {
          margin: 0 0 0 2px;
          font-size: 13px;
          flex: 1 1 auto;
          justify-content: flex-end;
          line-height: 13px;
          p {
            margin: 0;
          }
        }
        .samples-wrapper {
          align-items: flex-start;
          padding-left: 15px;
          flex-wrap: no-wrap;
          margin-top: 11px;
          justify-content: flex-start;
          overflow-x: scroll;
          overflow-y: hidden;
          width: 90vw;
          width: 90vw;
          .inner-wrapper {
            align-content: flex-start;
            min-width: fit-content;

            .sample-container.disabled {
              opacity: 0.5;
            }
            .sample-container {
              flex: 1 1 auto;
              align-self: flex-start;
              .sample-image-border {
                display: none;
                padding: 5px;
                border: 1px solid darkgray;
                background-color: white;
                border-radius: 10px;
                width: 128px;
                .sample-img {
                  height: 5vh;
                  display: flex;
                  margin: 0 auto;
                }
              }
              .sample-image-info {
                width: 100%;
                .sample-image-info-2 {
                  justify-content: flex-start;
                }
                md-checkbox.md-default-theme.md-checked .md-icon,
                md-checkbox.md-checked .md-icon {
                  background-color: transparent;
                  border: 1px solid white;
                  border-radius: 4px;
                }
                md-checkbox.md-default-theme:not(.md-checked) .md-icon,
                md-checkbox:not(.md-checked) .md-icon {
                  border-color: white;
                  border-radius: 4px;
                }
                .sample-name {
                  font-weight: 500;
                  font-size: 13px;
                  font-size: 13px;
                  margin: 0 2px 0 2px;
                  flex: 1 1 auto;
                  align-self: center;
                  justify-self: flex-start;
                  white-space: nowrap;
                  max-width: 140px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .price {
                  font-size: 20px;
                  font-weight: 600;
                }
              }
            }
          }
          .math-plus {
            display: none;
            font-size: 34px;
            color: #4a554c;
            padding: 0 20px 0 27px;
            flex: 1 1 auto;
            padding-bottom: 2vh;
          }
          .sample-total-container {
            align-self: stretch;

            flex: 1 1 40%;
            .md-button {
              align-self: initial;
              margin-top: 0;
              margin-bottom: 0;
            }

            .sample-total-wrapper {
              align-content: flex-end;
              align-items: flex-end;
              justify-content: center;
              .math-equal {
                font-size: 26px;
                line-height: 31px;
                color: black;
              }
              .sample-total {
                font-size: 28px;
                line-height: 31px;
                padding-left: 10px;
              }
            }
          }
        }
      }
    }
    #get-samples samples-wrapper .inner-wrapper .sample-images.disabled {
      opacity: 0.6;
      /* background-color: #9e9e9e !important; */
    }
  }
}

ftv-swiper-container.ng-isolate-scope.swiper-fullscreen {
  position: fixed !important;
  top: -20px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
ftv-swiper-container {

  .swiper-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

    /*
  .box {
    display:flex;
    flex-direction: column;
    height:100%;
    width:100%;
    justify-content: center;
  }
    .swiper-slide img {
    max-height: 90%;
    max-width: 90%;
    display: flex;
    margin: 0;
    padding: 0;
    min-height: 0;
    min-width: 0;
    align-self: center;
  }
  */
  .box {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    height:100%;
    width:100%;
    .image-type{
      text-align: center;
      font-size: 24px;
      padding: 0;
      margin: 0;
      color: #848484;
      mix-blend-mode: color-dodge;
    }
  }

  .swiper-slide img {
    width: auto;
    height: auto;
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }
  .slider-entry {
    background-color: #384652;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swiper-full-screen-close {
    position: absolute;
    top: 25px;
    right: 9px;
    color: white;
    font-size: 22px;
    background-color: #d76713;
    border-radius: 15px;
    padding-left: 7px;
    padding-right: 7px;
    cursor: pointer;
    z-index: 9999;
  }
  .swiper-button-disabled{
    color: #5f5a5a !important;
  }
  .swiper-button-next, .swiper-button-prev {
    color: #626762 !important;;
  }

  .swiper-full-screen-icon {
    position: absolute;
    right: 0px;
    top: 0px;
    color: white;
    z-index: 999;
  }
  ftv-get-samples {
    width: 100%;
    height: 100%;
    #get-samples {
      height: 100%;
      min-width: 100% !important;
    }
    .sample-image-wrapper {
      justify-content: center;
    }
  }
}
