.right-bar md-sidenav.frame {
  .filter-wrapper.cat-color {
    display: flex;
    flex: 1 1 100%;
  }
  .vAccordion--default v-pane-header:before {
    right: 5px;
    top: 9px;
  }
  #btn-close-frame-browser {
    position: absolute;
    right: 0;
    top: 8px;
    z-index: 99;
  }
  .filter-category {
    /* background-color: #a99f80;
    v-pane-content {
      background-color: white;
    }
    */
    .md-headline {
      color: white;
      background-color: #b1a88b;
      padding: 5px;
    }
  }

  a:-webkit-any-link {
    text-decoration: none;
  }

  /* min-width: 304px; */
  width: 100%;
  max-width: 100vw;

  .md-headline {
    clear: left;
  }

  // experimental
  // the below SASS will calculate media queries based
  // on the info entered below

  /////////////////////////////////////////////////////////
  .vAccordion--default v-pane-content > div {
    opacity: 1;
    padding-bottom: 30px;
  }
  .vAccordion--default v-pane-header {
    margin-bottom: 0;
  }

  .filter-entry {
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    background-color: #384652;
    margin: 9px 5px;
    border-radius: 10px;
    color: white;
    padding: 4px 11px;
    .filter-count {
      margin-left: 5px;
    }
    &.selected {
      background-color: #d76713 !important;
      font-weight: bold;
      color: #dcdcdc !important;
    }
    img {
      height: 20px;
      width: 20px;
      border: 1px solid lightgray;
    }
  }

  .filter-entry.cat-color {
    background-color: transparent !important;
    color: darkslategrey;
    min-width: 63px;
    .filter-name {
      margin-left: 5px;
    }

    &.selected {
      color: #d76713 !important;
      font-weight: bold;
    }
  }

  .image-grid {
    width: 100%;
    // max-width: 1310px;
    margin: 0 auto;
    overflow: hidden;
    padding: 10px 5px 0;
  }

  .swym-button {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 8;
  }
  .swym-button.swym-add-to-wishlist-view-product.swym-added {
    opacity: 0.7;
  }

  .swym-button.swym-add-to-wishlist-view-product {
    background: none;
    font-family: "swym-icons";
    font-size: 20px;
    font-weight: inherit;
    color: #ff4500;
    line-height: 100%;
    box-shadow: none;
    -webkit-appearance: none;
    min-width: 0px;
    max-width: 60px;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    outline: none;
    display: inline-block;
    height: auto;
    overflow: visible;
  }
}
.basic__img{
  max-width: 100%;
}

.frame-category{
  background-color: #f5f3f7;
}

 #frame-grid img.basic__img {
    width: 100%;
    height: 100%;
    border: 3px solid #cecece33;
    .md-default-theme {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  .image--basic.ng-scope {
    width: 100%;
    height: 100%;
  }
.right-bar md-sidenav.frame .sample-entry {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .sample-title {
    height: auto!important;
    width: initial;
    min-height: auto!important;
}
.btn-add-sample {
  font-size: 85%;
  min-width: 90px;
  padding: 8px;
}
}

.product-labels {
  position: absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  .product-labels-relative {
    position: relative;
    display: flex;
    height: 82%;
    width: 96%;
    flex-direction: column;
    justify-content: flex-end;
    img.product-label {
      width: initial !important;
      height: initial !important;
      max-height: 22px;

    }
    img.product-label.ng-scope.aligncenter {
      align-self: center;
    }
    img.product-label.ng-scope.alignleft {
      align-self: flex-start;
      max-height: 25px;
    }
  }
}
#frame-grid{

  .expand__close {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #454545;
    font-size: 50px;
    line-height: 50px;
    text-decoration: none;
    z-index: 999;
  }

  .expand__close:before {
    content: "×";
  }

  .expand__close:hover {
    color: #fff;
  }

  .arrow--up {
    border-bottom: 15px solid #676968;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    height: 0;
    width: 0;
    margin: -12px auto 0;
  }
   .price {
    font-size: 2rem;
    margin: 20px 0;
  }
}
md-grid-list md-grid-tile.detail-tile figure {
  align-items: flex-start;
  justify-content: flex-start;
}
.right-bar md-sidenav.frame .overlay {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
  .style-name {
    display: inline;
    font-weight: 800;
    color: #b1a88b;
    background-color: white;
    /*
    background-color: rgba(232,232,232,.87);
    -webkit-box-shadow: 0 0 20px 0 #ccc;
    box-shadow: 4px 5px 20px 0 #a1a0a3;
    */
    padding: 1px 10px;
    position: relative;
    bottom: 2px;
}
@media all and (max-width: 530px) {
}
#frame-grid button {
  width: fit-content;
  &.btn-add-sample{
    padding: 7px 10px;
  }
}
@media all and (max-width: 599px) {
  /* #frame-grid img.basic__img {
    width: 175px;
    width: 100%;
    height: 100%;
    .md-default-theme {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  */
  #frame-grid {
    .frame-details {
      display: flex;
      min-height: 0;
      position: relative;
      max-height: 100%;
      width: 100%;

      .wrapper {
        display: flex;
        background: #676968;
        justify-content: flex-start;
        width: 100%;
        flex-wrap: wrap;
        align-content: flex-start;
        font-size: .9em;
        .expand__close {
          top: 0px;
          right: 10px;
        }
        button {
          border: none;
          background-color: #d66713;
          color: #fff;
          padding: 15px 10px;
          &:hover {
            background-color: #b9733f;
          }
        }

        .preview-images {
          display: flex;

          width: 100%;
          max-height: 40%;
          padding: 5px;
          flex-wrap: nowrap;;
          .preview-image {
            display: flex;
            width: 75%;
            flex-direction: column;
            justify-content: flex-end;
            img {
              display: block;
              width: 100%;
              height: auto;
              box-shadow: 0px 5px 9px 3px #737373;
            }
          }
          .preview-column {
            display: flex;
            flex-direction: column;
            width: 25%;
            margin: 5px;
            max-width: 25%;
            align-items: flex-start;
            justify-content: flex-end;
            img {
              display: inline-block;
              width: auto;
              height: 29%;
              cursor: pointer;
            }
          }
        }
        .price-column {
          display: flex;
          flex-direction: column;
          min-height: 0;
        }
        .description-column {
          display: flex;
          flex-direction: column;
          min-height: 0;
        }
        .content-row {
          display: flex;
          flex-direction: row;

          padding: 15px;
          color: white;
          .contents {
            display: flex;
            flex-direction: column;
            width: 50%;
            font-weight: 500;
            flex-direction: column;
            letter-spacing: 0.11em;


          }
        }
      } // end wrapper
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 959px) {
  /*
  #frame-grid img.basic__img {
    width: 175px;
  }
  */
  #frame-grid {
    .frame-details {
      display: flex;
      min-height: 0;
      position: relative;
      max-height: 100%;
      width: 100%;
      .wrapper {
        display: flex;
        background: #676968;
        justify-content: flex-start;
        width: 100%;
        flex-wrap: wrap;

        button {
          border: none;
          background-color: #d66713;
          color: #fff;
          padding: 5px 10px;
          &:hover {
            background-color: #b9733f;
          }
        }

        .preview-images {
          display: flex;
          width: 100%;
          position: relative;
          overflow: hidden;
          height: 50%;

          .preview-image {
            display: inline-block;
            position: relative;
            top: 0;
            left: 0;
            margin: 5px;
            width: 65%;

            img {
              display: block;
              height: 100%;
              width: auto;
              box-shadow: 0px 5px 9px 3px #737373;
            }
          }
          .preview-column {
            display: inline-block;
            width: 25%;
            margin: 5px;
            max-width: 25%;
            img {
              display: inline-block;
              width: auto;
              max-height: 32%;
              cursor: pointer;
              padding-bottom: 6px;
            }
          }
        }
        .price-column {
          display: flex;
          flex-direction: column;
          min-height: 0;
        }
        .description-column {
          display: flex;
          flex-direction: column;
          min-height: 0;
        }
        .content-row {
          display: flex;
          flex-direction: row;

          padding: 15px;
          color: white;
          .contents {
            display: flex;
            width: 50%;
            flex-direction: column;
            // font-size: 0.8em;
            letter-spacing: .11em;
            .price {
              font-size: 2rem;
              margin: 20px 0;
            }
          }
        }
      } // end wrapper
    }
  }
}

@media only screen and (min-width: 600) and (max-width: 772px) {
}

@media only screen and (min-width: 960px) and (max-width: 1439px) {
  #frame-grid {
    .frame-details {
      display: flex;
      min-height: 0;
      position: relative;
      max-height: 100%;
      width: 100%;
      .wrapper {
        display: flex;
        background: #676968;
        justify-content: flex-start;
        width: 100%;
        flex-wrap: wrap;

        button {
          border: none;
          background-color: #d66713;
          color: #fff;
          padding: 10px 25px;
          &:hover {
            background-color: #b9733f;
          }
        }

        .preview-images {
          display: flex;
          width: 100%;
          position: relative;
          overflow: hidden;
          height: 50%;
          justify-content: center;
          .preview-image {
            display: flex;
            position: relative;
            width: 49%;
            margin: 5px;
            img {
              display: inline-block;
              height: 100%;
              border: 1px solid black;
              width: auto;
              box-shadow: 0px 5px 9px 3px #737373;
            }
          }
          .preview-column {
            display: flex;
            flex-direction: column;;
            width: 15%;
            margin: 5px;
            max-width: 15%;
            img {
              display: inline-block;
              width: auto;
              max-height: 32%;
              padding-top: 5px;
              cursor: pointer;
            }
          }
        }
        .price-column {
          display: flex;
          flex-direction: column;
          min-height: 0;
        }
        .description-column {
          display: flex;
          flex-direction: column;
          min-height: 0;
        }
        .content-row {
          display: flex;
          flex-direction: row;

          padding: 15px;
          color: white;
          .contents {
            display: flex;
            flex-direction: column;
            width: 50%;
            flex-direction: column;
            padding: 0 15px;
            letter-spacing: .11em;
            // font-size: 0.8em;
            .price {
              font-size: 2rem;
              margin: 20px 0;
            }
          }
        }
      } // end wrapper
    }
  }
}
@media only screen and (min-width: 1280px) {
  /*
  .image--basic{
    width: 100%;
    height: 100%;
  }

  .basic__img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
  */
  #frame-grid {
    .frame-details {
      display: flex;
      min-height: 0;
      position: relative;
      max-height: 100%;
      height: 100%;
      width: 100%;
      .wrapper {
        display: flex;
        background: #676968;
        justify-content: flex-start;
        width: 100%;
        flex-wrap: nowrap;
        padding: 5px;
        button {
          border: none;
          background-color: #d66713;
          color: #fff;
          padding: 10px 25px;
          &:hover {
            background-color: #b9733f;
          }
        }
        .preview-images {
          display: flex;
          height: 100%;
          width: 50%;
          align-items: center;
          padding-left: 11px;
          .preview-image {
            display: inline-block;
            position: relative;
            width: auto;
            height: 95%;
            img {
              min-height: 0;
              width: auto;
              height: 100%;
              box-shadow: 0px 5px 9px 3px #737373;
            }
          }
          .preview-column {
            display: flex;
            flex-direction: column;
            padding: 0 4px;
            max-width: 19.5%;
            img {
              display: inline-block;
              width: 100%;
              max-height: 32%;
              padding: 3px 4px;
              cursor: pointer;
            }
          }
        }
        .price-column {
          display: flex;
          flex-direction: column;
          min-height: 0;
        }
        .description-column {
          display: flex;
          flex-direction: column;
          min-height: 0;
        }
        .content-row {
          display: flex;
          flex-direction: row;
          flex: 1 1 50%;
          padding: 15px;
          color: white;
          .contents {
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;
            width: 50%;
            max-width: 50%;
            font-weight: 500;
            flex-direction: column;
            .right-bar md-sidenav.frame .price {
              font-size: 2rem;
              margin: 20px 0;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 981px) and (max-width: 1279px) {
  .error-message a {
    color: darkblue;
    text-decoration: underline;
  }
}
