.installation-variants {
  > div {
    margin-left: 10px;
    transition: all linear 1s;

    img {
      width: 100%;
      height: 100%;
      display: flex;
    }

    &:first-child{
      margin-left: 0;
    }

    &.ng-hide {
      display: block !important;
      visibility: hidden;
      opacity: 0;
    }

    .variant-text, .variant-question {
      min-height: 40px;
      padding: 10px;
      background: #eee;
    }
  }


  .installation-variant {
    position: relative;
    margin-left: 2px;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    .md-button.md-fab.md-mini {
      transform: scale(0.5);
      transform-origin: 0 100%;
    }

    .overlay {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.7);
      padding: 5px;
      color: white;
      z-index: 1000;
      opacity: 0;
      transition: all 0.3s;
    }

    &:hover .overlay {
      opacity: 1;
    }
  }
}

button.install-option-btn.md-raised.md-primary.md-button.md-default-theme.md-ink-ripple.selected {
  background-color: #148637
}
.method-recommendations {
  border: solid 1px #d8d8d8;
  margin-top: 30px;

  .btn-group {
    margin-top: -24px;
  }
}
.select-option-btn-wrapper{
  .price{
    padding: 9px 5px 8px!important;
    font-weight: 600;
    align-self: center;
    background-color: #ab5110;
    color: white;
    margin-right: 25px;
  }
}
.not-recommended {
  .list-thumb {
    filter: grayscale(100%);
  }
}

@media(max-width: 959px) {

  .installation-variants {
    > div {
      max-height: 500px;
      margin-left: 0;
      margin-top: 10px;

      &:first-child{
        margin-top: 0;
      }

      &.ng-hide {
        height: 0;
      }
    }
  }
  .select-option-btn-wrapper{
    .price{
      flex: 1 1 auto;
      margin: 0 auto;
      text-align: center;
    }
  }
}
