.saved-dialog {
  md-icon {
    width: 48px;
    height: 48px;
  }
  .add-to-cart{
    max-width: 50%;
  }
  .md-button.md-icon-button {
    line-height: 48px;
    width: 64px;
    height: 64px;
  }

  .md-dialog-content h2 {
    text-align: center;
  }

  .preview-image {
    height: 350px;
    position: relative;
    overflow: hidden;
    background: #f0ebdc;
    box-shadow: inset 0 0 60px -20px #000;

    .image {
      width: 100%;
      top: -20%;
      height: 140%;
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
}
