.right-bar md-sidenav.art {
  .filter-wrapper.cat-color{
    display:flex;
    flex: 1 1 100%;
  }
  .filter-entry.body-content img {
    height: 71%;
    width: 100%;
  }

  .filter-entry.body-content {
    cursor: pointer;
    background-color:transparent;
    margin-bottom: 15px;
    border-radius: none;
    color: #384650;
    padding: 0;
  }
  .category-container .filter-entry.content{
    background-color: none;
  }
  #filter-sidebar v-pane-content.filter-content>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .vAccordion--default v-pane-header:before {
    right: 5px;
    top: 16px;
  }
  .filter-category{
    /* background-color: #a99f80;
    v-pane-content {
      background-color: white;
    }
    */
    .md-headline{
      color: white;
      background-color: #b1a88b;
      padding: 5px;
    }
  }
  .filter-entry {
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    background-color: #384652;
    margin: 9px 5px;
    border-radius: 10px;
    color: white;
    padding: 4px 11px;
    &.selected {
      background-color: #d76713 !important;
      font-weight: bold;
      color: #dcdcdc !important;
    }
    img {
      height: 20px;
      width: 20px;
      border: 1px solid lightgray;
    }
  }
  .filter-entry.cat-color {
    background-color: transparent !important;
    color: darkslategrey;
    min-width:63px;
    .filter-name{
      margin-left: 5px;
    }
    .filter-count{
      margin-left: 5px;
    }
    &.selected {
      color: #d76713 !important;
      font-weight: bold;
    }
  }

  .category-container{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;;
    flex: 1 1;
    text-align: center;
    align-content: flex-start;
    margin: 0 20px;
    .filter-entry{
      max-width: 200px;
    }
  }


  @media all and (max-width: 599px) {
    .category-container .filter-entry{
        max-width: 149px;
    }
  }
  .md-headline{
    border:none;
    font-size: 17px;
  }
  button{
    color:#b27147
  }
  .preview-text{
    font-size: 17px;
    line-height: 24px;
    padding-bottom: 4px;
    margin: 0;
    font-weight: 300;
    margin-left: 6px;
  }
  .wrapper{
    height:100%;
  }
  .preview-text.price{
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .sample-on-canvas-wrapper{
    border-top:1px solid #a99f80;
    padding-top:20px;
    button {
      font-size: 13px;
      padding: 8px;
    }
  }
  .column--center,
  .contents {
    width: auto;
  }
  #btn-close-frame-browser {
    position: absolute;
    right: 0;
    top: 8px;
    z-index: 99;
  }
  img {
    display:block;
    width: 96%;
    max-width: 100%;
  }

  a:-webkit-any-link {
    text-decoration: none;
  }

  /* min-width: 304px; */
  width: 100%;
  max-width: 100vw;

  .md-headline {
    clear: left;
  }

  // experimental
  // the below SASS will calculate media queries based
  // on the info entered below

  /////////////////////////////////////////////////////////
  .vAccordion--default v-pane-content > div {
    opacity: 1;
    padding-bottom: 50px;
  }
  .vAccordion--default v-pane-header {
    padding: 5px 0;
    margin-bottom: 0;
  }
  .filter-entry {
    margin-top: 15px;
    cursor: pointer;
  }

  .image-grid {
    width: 100%;
    // max-width: 1310px;
    margin: 0 auto;
    overflow: hidden;
    padding: 10px 5px 0;
  }

  .image__cell {
    float: left;
    position: relative;
  }

  .image--basic {
    padding: 0 5px;
    margin-top: 20px;
    .product-labels {
      position: absolute;
      height: 100%;
      width: 100%;
      .product-labels-relative {
        position: relative;
        height: 82%;
        width: 96%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        img.product-label {
          width: initial !important;
          height: initial !important;
          max-height: 22px;
        }
        img.product-label.ng-scope.aligncenter {
          align-self: center;
        }
        img.product-label.ng-scope.alignleft {
          align-self: flex-start;
          max-height: 25px;
        }
      }
    }
  }

  .basic__img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }

  .image__cell.frame-collapsed .image--basic {
    cursor: pointer;
  }

  .sample-list,
  .sample-entry,
  .sample-title {
    height: auto !important;
    width: initial;
    min-height: auto !important;
  }

  .sample-entry {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn-add-sample {
      font-size: 85%;
      min-width: 90px;
      padding: 8px;
    }
  }

  .image--expand {
    position: relative;
    left: -5px;
    padding: 0 5px;
    box-sizing: content-box;
    overflow: hidden;
    background: #324553;
    color: #ffffff;
    max-height: 0;
    transition: max-height 0.3s ease-in-out, margin-bottom 0.1s 0.2s;
  }

  .row-entry {
    padding-right: 10px;
    border-right: 1px solid lightgray;
  }

  .swym-button {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 8;
  }
  .swym-button.swym-add-to-wishlist-view-product.swym-added {
    opacity: 0.7;
  }

  .swym-button.swym-add-to-wishlist-view-product {
    background: none;
    font-family: "swym-icons";
    font-size: 20px;
    font-weight: inherit;
    color: #ff4500;
    line-height: 100%;
    box-shadow: none;
    -webkit-appearance: none;
    min-width: 0px;
    max-width: 60px;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    outline: none;
    display: inline-block;
    height: auto;
    overflow: visible;
  }

  .image__cell.frame-collapsed .arrow--up {
    height: 10px;
    width: 100%;
  }

  .image__cell.frame-expanded .arrow--up {
    border-bottom: 15px solid #676968;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    height: 0;
    width: 0;
    margin: 2px auto 0;
  }

  .expand__close {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #454545;
    font-size: 50px;
    line-height: 50px;
    text-decoration: none;
  }

  .expand__close:before {
    content: "×";
  }

  .expand__close:hover {
    color: #fff;
  }

  .image--large {
    max-width: 100%;
    height: auto;
    display: block;
    padding: 40px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  .column--left-row {
    img {
      display: block;
      background-color: white;
    }
  }
  .column--left {
    background-color: white;
  }

  .wrapper button {
    border: none;
    background-color: #b27147;
    color: #fff;
    padding: 10px 25px;
  }
  .wrapper button:hover {
    background-color:  #d66713;
  }
  .overlay {
    text-align: center;
    .style-name {
      display: inline;
      font-weight: 800;
      color: #a7a6a6;
      background-color: rgba(232, 232, 232, 0.87);
      padding: 1px 10px;
      -webkit-box-shadow: 0 0 20px 0 #ccc;
      box-shadow: 0 0 20px 0 #ccc;
    }
  }
  @media all and (max-width: 530px) {
    .image__cell.frame-expanded .image--expand {
      max-height: 65vh !important;
      padding: 10px !important;
      width: 94vw !important;
      min-height: 560px!important;
    }
    .column--left-row img {
      display: inline-block !important;
      width: 22% !important;
      height: auto !important;
    }
    .column--left-row {
      min-height: 50px !important;
      height: 10vh !important;
      width: 100vw !important;
      height: auto !important;
    }
    .preview-image{
        max-width:80% !important;
    }

  }

  @media all and (max-width: 599px) {
    .filter-wrapper.cat-color{
      display:flex;
      flex: 1 1 50%;
    }
    .image__cell {
      max-width: 47vw;
    }
    .image__cell.frame-expanded .image--expand {
      width: 96vw;
    }
   .preview-image {
      width: 95vw;
    }
  }


  @media all and (max-width: 767px) {
    .image__cell {
      // max 2 per line
      max-width: 38vw;
      font-size: 80%;
    }

    .image__cell.frame-expanded .image--expand {
      max-height: 93vh;
      padding: 10px;
      width: 76vw;
      min-height: 650px;
    }

    .preview-image{
      width:75vw;
  }
    .column--left img {
      width: auto;
      height: 100%;
    }
    .column--left-row {
      max-width: 94vw !important;
      img {
        width: auto;
        height: 32%;
        margin: 0 5px 8px;
        display: block;
      }
    }
    .col-inline {
      vertical-align: top;
      display: inline-block;
    }
    .column--xxxx {
      width: 100%;
      .column--center {
        width: 100%;
      }
    }
    .wrapper {
      text-align: center;
      flex-wrap: wrap;
    }

    // 2nd
    .frame-category .frame-entry:nth-of-type(2n + 1) .image--expand {
      margin-left: -100%;
    }

    // start of next line
    .frame-category .frame-entry:nth-of-type(2n + 4) {
      clear: left;
    }

    .layout-toggle {
    //  display: flex;
   //   flex-direction: column;
      width: 100vw;
      .flex-20 {
        width: 100vw;
      }
      .flex-80 {
        .image-grid {
          .items-container {
            .frame-category {
              .image--basic {
                max-width: 50vw;
              }
            }
          }
        }
      }
      .layout-column {
        width: 100vw;
      /*  min-width: 100vw; */
      }
    }
    .preview-pane {
      width: 100vw;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 980px) {
    .image__cell {
      // max 4 per line
      max-width: 30%;
    }
    .image__cell.frame-expanded .image--expand {
      max-height: 85vh;
      padding: 10px;
      width: 76vw;
      min-height: 680px;
    }
    .column--left img {
      width: auto;
      height: 100%;
    }

    // 3rd
    .frame-category .frame-entry:nth-of-type(3n) .image--expand {
      margin-left: -100%;
    }
    // 3rd
    .frame-category .frame-entry:nth-of-type(3n + 1) .image--expand {
      margin-left: -200%;
    }
    // start of next line
    .frame-category .frame-entry:nth-of-type(3n + 5) {
      clear: left;
    }
/*
    .layout-toggle {
      .flex-20 {
        width: 100vw;
      }
    }
*/
    .col-inline {
      vertical-align: top;
      display: inline-block;
    }

    .column--left {
      img {
        width: auto;
        height: 100%;
      }
    }
    .column--left-row {
      height: 100%;
      max-height: 300px;
      img {
        width: auto;
        height: 32%;
        margin: 0 5px 8px;
        display: block;
      }
    }

    .column--center,
    .column--right {
      width: 49%;
    }
  }
  @media only screen and (min-width: 769px) and (max-width: 1439px) {
    font-size: 90%;
  }

  @media only screen and (min-width:981px) and (max-width: 1187px) {
    .image__cell.frame-expanded .image--expand {
      max-height: 85vh;
      padding: 10px;
      width: 76vw;
      min-height: 838px !important;
    }
  }
  @media only screen and (min-width: 1515px) {
    .image__cell.frame-expanded .image--expand {
      max-height: 85vh;
      padding: 10px;
      width: 76vw;
      min-height: 541px!important;
    }
  }
  @media only screen and (min-width: 1280px) {

    .image__cell {
      // max 5 per line
      max-width: 20%;
    }
    // .image__cell.frame-expanded .image--expand, .image--expand .wrapper {
      .image__cell.frame-expanded .image--expand {
      max-height: 85vh;
      padding: 0;
      width: 76vw;
      min-height: 400px;;
    }
    .wrapper {
      align-items: stretch;
      display: flex;

    }

    .column--left{
      display:flex;
      flex-direction: column;
    }
    .column--left img {
      width: auto;
      height: 100%;
      display: block;
    }

    .frame-category .frame-entry:nth-of-type(5n + 3) .image--expand {
      margin-left: -100%;
    }
    .frame-category .frame-entry:nth-of-type(5n + 4) .image--expand {
      margin-left: -200%;
    }
    .frame-category .frame-entry:nth-of-type(5n + 5) .image--expand {
      margin-left: -300%;
    }
    .frame-category .frame-entry:nth-of-type(5n + 6) .image--expand {
      margin-left: -400%;
    }
    // start of next line
    .frame-category .frame-entry:nth-of-type(5n + 7) {
      clear: left;
    }

    .image__cell.frame-expanded .image--expand {
      max-height: 55vh;
      padding: 10px;
      width: 79vw;
    }
    .column--center {
      display: flex;
    }
  }
  @media only screen and (min-width: 981px) and (max-width: 1279px) {
    .layout-toggle {
      display: flex;
      flex-direction: column;
    }
    .image__cell {
      // max 4 per line
      max-width: 25%;
    }
    .image__cell.frame-expanded .image--expand {
      width: 76vw;
      height: 30vw;
      padding: 10px;
      min-height: 375px;
    }
    // 2nd
    .frame-category .frame-entry:nth-of-type(4n -1) .image--expand {
      margin-left: -100%;
    }
    // 3rd
    .frame-category .frame-entry:nth-of-type(4n) .image--expand {
      margin-left: -200%;
    }
    // 4th
    .frame-category .frame-entry:nth-of-type(4n + 1) .image--expand {
      margin-left: -300%;
    }
    // start of next line
    .frame-category .frame-entry:nth-of-type(4n + 2) {
      // this float clear push the end of line floats below the image-expand box
      clear: left;
    }
    .preview-image{
      width: fit-content;
    }

    .overlay {
      text-align: center;

      .style-name {
        display: inline;
        font-weight: 800;
        color: #a7a6a6;
        background-color: rgba(232, 232, 232, 0.87);
        padding: 1px 10px;
        box-shadow: 0px 0px 20px 0 #ccc;
      }
    }

  }
  .clearfix:after {
    content: " "; /* Older browser do not support empty content */
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  //////////////////////////////

  .virtual-container {
    height: 100%;
    overflow: auto;
  }

  .thumb-container {
    padding-right: 10px;
    position: relative;

    &.selected {
      background: #eee;
    }

    .overlay {
      cursor: pointer;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.7);
      padding: 5px;
      color: white;
      z-index: 1000;
      opacity: 0;
      transition: all 0.3s ease-in;
      text-align: center;
      max-height: 0;
      overflow: hidden;
      font-size: 0.8em;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 10px;
    }

    &:hover .overlay {
      opacity: 1;
      max-height: 100%;
    }
  }

  .main-pane {
    border-right: solid 1px #bbb;
  }

  .preview-pane {
    // overflow: hidden;

    .no-preview {
      text-align: center;
    }

    ftv-frame-details {
      min-height: 100%;
    }
  }

  .error-message a {
    color: darkblue;
    text-decoration: underline;
  }
}
