:focus {
  outline: none;
}

a {
  cursor: pointer;
  color: #D76713;
}
@media print {
  #livechat-compact-container,
  #livechat-full,
  .no-print,
  .no-print *,
  .trustpilot-widget {
    display: none !important;
  }
}

html {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scrollable {
  overflow-y: auto;
}

.error {
  color: darkred;
}

.text-center {
  text-align: center;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  cursor: pointer;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: #928461;
  border: 0 none #ffffff;
  border-radius: 44px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background:#928461;
}

::-webkit-scrollbar-track {
  background: lightgray;
  border: 0 none #ffffff;
  border-radius: 24px;
}

::-webkit-scrollbar-track:hover {
  background: #666666;
}

::-webkit-scrollbar-corner {
  background: transparent;
}
