ftv-unsaved-dot.dirty {
  position: absolute;
  top: -7px;
  right: -7px;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: red;
}
