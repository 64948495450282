v-accordion, .preview-pane.layout-column.flex-20 {
    // background-color: #eae8de;
    background-color: white;
}
.vAccordion--default v-pane-content>div {
    transform: translate3d(0,9px,0);
}
.vAccordion--default v-pane-header {
    padding: 0;
    margin-bottom: 20px;
    border-bottom: none;
    -webkit-transition: all .25s;
    transition: all .25s;
}
.layout-padding.ng-scope.layout-column {
    /* position: sticky; */
    /* position: -webkit-sticky; */
    top: 0; /* required */
    padding: 0 10px;
    overflow-y: scroll;
}
.ng-scope.layout-row {
    padding: 0;
}
#accordionA {
    padding: 0;
    md-input-container{
        margin:0;
        width:95%;
    }
    md-input-container:not(.md-input-has-value):not(.md-input-has-placeholder) input:not(:focus) {
        border-color: transparent;
    }
}
#filter-search{
    background-color:white;
    border-radius: 25px;
    background-image: url("assets/configurator/search24x24.png");
    background-repeat: no-repeat;
    padding: 5px 0 5px 35px;
    background-position: 5px center;
}
#filter-sidebar {
    v-pane-content.filter-content > div{ /*transclude div created by angular */
        display: flex;
        flex-wrap: wrap;
    }
    .filter-entry {
        img {
            height: 20px;
            width:20px;
        }
        &.selected{
            color: #d76713;
            font-weight: bold;
        }
    }

    .filter-header{
        padding: 2px 0;
        background-color: #eae8df;
        .filter-clear{
            display:block;
            color: #d97021;
            font-variant: all-petite-caps;
            cursor: pointer;
            .close-x {
                background-color: #d76713;
                height: 100%;
                width: 15px;
                display: inline-block;
                text-align: center;
                color: white;
            }
        }
    }

}

ul.filter-menu-list {
    padding: 0;
    margin: 0;
}

#filter-header, #toggle-menu-mobile {
    display: inline-block;
}


@media all and (max-width: 530px) {
    #toggle-menu-mobile {
        visibility: visible;
    }
}

@media all and (min-width: 531px) {
    #toggle-menu-mobile {
        visibility: hidden;
    }
}
@media all and (min-width: 599px) {
    #filter-sidebar .filter-header {
        padding: 2px 0;
    }
}
