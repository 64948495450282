ftv-summary-bar {
  .disabled-link{
    background-color: #aaaaaa;
    color: #595959;
  }
  .section-header{
    background-color: #aaaaaa;
    color: #313131;
  }
  display: flex;
  flex-direction: column;
  background: #fff;
  .top-banner{
    width: 100vw;
    background-color: #928461;
    color: white;
    padding: 3px;
  }
  .hide-link{
    display: none;
  }
  .bottom-bar > div,
  .top-bar > div {
    margin: 5px 10px;

    &:first-child {
      /* margin-left: 0; */
      margin-left: 15px;
    }
  }

  .top-bar {
    font-size: 15px;
    padding: 0 10px;
    .top-link{
      color: #314550;
      font-weight: 400;

      &:first{
        color: orange;
      }
    }
    a {
      color: #676968;
    }
    .counter {
      color: white;
      border-radius: 50%;
      width: 17px;
      height: 17px;
      padding: 0px;
      border: 2px solid rgb(215,103,19);
      text-align: center;
      font: 14px  monospace;
      line-height: 16px;
      position: relative;
    }

    .my-designs{
      cursor: pointer;
      display: flex;
      .counter {
        background-color:rgb(215,103,19);
        top: -1px;
        right: -3px;
      }
    }
    .mini-cart-icon {
      z-index: 9990;
      display:flex;
      cursor: pointer;
      .counter {
        background-color:rgb(215,103,19);
        top: -3px;
        right: 1px;
      }
    }
  }

  .bottom-bar {
    padding-left: 10px;
  }

  .type-name {
    font-size: 28px;
    color: #272928;
  }

  .separator {
    border-left: solid 1px #c4c4c4;
    min-height: 20px;
    min-width: 2px;
  }

  .logo {
    width: 149px;
    align-self: flex-end;
    margin-right: 20px;
    svg {
      width: 100%;
      display: flex;
      height: auto;
    }
  }

  a {
    text-decoration: none;
  }

  .item-box {
    align-items: baseline;
  }

  .item-label {
    font-size: 13px;
    color: #9a9c9b;
    margin-right: 5px;
  }

  .item {
    color: #676968;
    font-size: 21px;
  }

  .highlight {
    color: #d76713;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    white-space: nowrap;
    margin-top: 7px;
  }

  .design-type-popup > a {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAQAAAAngNWGAAABLklEQVQoz9XSO0hCYQBA4ZNCSxFCRJllBC2SQlTiUE09IKUhwYYWIceGaHYJgsAG14iWwKGWSLJQwcRCCXIRTcNuS0KotAWRg/p3G4oylbaIs37jQfC7+ENYbj1ckoiTJEEEL34CpMhyS4wjiuQpsGN/1HAzao5Khqawc/7q1MbqHkJ/Fx9pCNXmc4QlQEZrDSJ098nxurB39hJhSofGyPCgsp0hBnMJ0w84MHGNmExFhkK8Q046Vg4QffnI3Dc4PJVETEdd2ggfkFKbYx/R8+S1fEK9MY2YuSj0u/gKcbC+q6i0v/oXAwoZ6gxZmcVy3QVqYJVqi9Mt05LPljLqZWYNSuoc9SBOtjeVFdWzpoiw+8JdEo0hWxvKMmL52KMK0xyy5l7wvqg81MB/8OMb8M5uqrUw66AAAAAASUVORK5CYII=");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 14px;
    padding-right: 18px;
  }
}
@media ( max-width: 1350px) {
  ftv-summary-bar .type-name {
    flex: 0 1 100%;
  }
}