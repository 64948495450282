.inline-input {
  > button {
    margin-top: 18px;
  }
  > div {
    margin-top: 28px;
    margin-right: 4px;
  }
  > md-input-container {
    margin-right: 4px;
  }
}

md-input-container.editable-controls {
  padding: 0;
  margin: 0;
}

md-input-container.editable-controls [ng-message] {
  position: static;
}

md-input-container .hint {
    float: left;
    padding-top: 5px;
    font-size: 12px;
    line-height: 14px;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    color: grey;
}
