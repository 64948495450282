.capture-form {

  .overlay {
    background: #000;
    opacity: 0.3;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 98;
  }

  .box {
    position: fixed;
    bottom: 0;
    right: 10px;
    max-width: 400px;
    width: calc(100% - 20px);
    background: #fff;
    z-index: 99;
    overflow: hidden;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0 7px 8px -4px rgba(0,0,0,.2),0 13px 19px 2px rgba(0,0,0,.14),0 5px 24px 4px rgba(0,0,0,.12);
    transition: all 0.3s ease-in-out;

    h2, p, .error {
      padding: 0 16px;
    }

    &.step-1 {
      height: 445px;
      max-height: 90%;

      md-input-container {
        min-width: calc(50% - 16px);
      }
    }

    &.step-2 {
      height: 750px;
      max-height: 90%;
    }

    &.step-3 {
      height: 370px;
      max-height: 90%;
    }

    &.step-4 {
      height: 480px;
      max-height: 90%;
    }

    &.step-5 {
      height: 300px;
      max-height: 90%;
    }

    md-checkbox {
      margin: 0;
    }
  }

  .pane-content {
    position: relative;
    overflow-y: auto;
  }
}

span.continue-progress {
  margin-right: 20px;
}

.hbspt-form {
  display: none;
}
