
.md-dialog-container.lightbox-dialog{
  // trial hack for not losing height on safari
  align-items: initial !important;
}
md-dialog.lightbox-container.md-dialog-fullscreen {
  // trial hack for not losing height on safari
  align-self: center !important;
  min-height:25vh !important
}

  #lightbox-container {
    min-height: 1px !important; // trial hack for not losing height on safari
    md-content._md {
      overflow-x: hidden;
      overflow-y: auto;
    }

    h2 {
      margin-left: 50px;
    }
    md-toolbar.product-info-light-box {
      min-height: 1px;
      .close-btn {
        background-color: #a97450;
        border: 1px solid;
        border-radius: 20px;
        position: absolute;
        top: 3px;
        right: 7px;
        width: 35px;
        height: 35px;
        &:after {
          content: "x";
          font-size: 27px;
          color: white;
          position: relative;
          top: -2px;
          left: 10px;
          font-weight: 100;
          cursor: pointer;
        }
      }
    }
    .product-info-light-box {
      .light-box-entries {
        overflow: auto;
        // height: auto;
        // width: 80%;
        // display: flex;
        max-width: 100%;
        height: auto;
        justify-content: center;
        text-align: center;
        margin-top: 20px;

        .lightbox-entry {
          background-color: #f4f4f4;
          position: relative;
          &.wistia-video {
            max-width: 100%;
            height: auto;
            position: relative;
            margin: 0 auto;
          }
          // wistia needs full size so the js calculates dimension
          .wistia_swatch {
            display: none !important;
          }
          div {
            max-height: 44vh;
            width: auto;
          }
          img {
            max-width: 600px;
            height: auto;
            width: 100%;
          }
        }
      }
      .lightbox-content-wrapper {
        margin: 10px 50px;
        line-height: 2em;
        font-size: 16px;
      }
      .swipe-next {
        border-top-right-radius: 0.125rem;
        border-bottom-right-radius: 0.125rem;
        right: -2.5rem;
      }
      .swipe-prev {
        border-top-left-radius: 0.125rem;
        border-bottom-left-radius: 0.125rem;
        left: -2.5rem;
      }
      .swipe-prev,
      .swipe-next {
        background-color: #3e414621;
        font-family: "pag-iconfont";
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
        position: absolute;
        display: inline-block;
        width: 45px;
        height: 75px;
        top: 36%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }
      .swipe-prev {
        left: 2px;
        md-icon {
          height: 73px;
          width: 80px;
          position: relative;
          left: -17px;
          top: 1px;
          color: #fff;
        }
      }
      .swipe-next {
        right: 1px;
        md-icon {
          height: 60px;
          width: 80px;
          position: relative;
          left: -23px;
          top: 7px;
          color: #fff;
        }
      }
    }
    md-footer {
      padding: 10px;
      align-items:center;
      .md-primary {
        margin: 0 !important;
        background-color: #d76712;
        padding: 0px 15px !important;
      }
      .price {
        padding: 9px 5px 8px 5px !important;
        font-weight: 600;
        align-self: center;
        background-color: #ab5110;
        color: white;
        margin-right: 25px;
      }
    }
  }


@media (max-width: 599px) {
  #lightbox-container {
    .product-info-light-box {
      .light-box-entries {
        overflow: auto;
        // height: auto;
        // width: 80%;
        // display: flex;
        max-width: 100%;
        height: auto;
        justify-content: center;
        text-align: center;
        margin-top: 20px;

        .lightbox-entry {
          background-color: #f4f4f4;
          position: relative;
          &.wistia-video {
            max-width: 100%;
            height: auto;
            position: relative;
            margin: 0 auto;
          }
          // wistia needs full size so the js calculates dimension
          .wistia_swatch {
            display: none !important;
          }
          div {
            max-height: 44vh;
            width: auto;
          }
        }
      }
    }
  }
}
