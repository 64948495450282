ftv-popup {
  .ui-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(18,18,18,0.7);
    z-index: 1000; // Goes above the top and side bars of the store front otherwise some data may be hidden

    &.ng-hide-add.ng-hide-add-active,
    &.ng-hide-remove {
      background: rgba(18,18,18,0);
      transition: all ease-out .5s;

      .ui-popup {
        opacity: 0;
        transform: scale(0.9);
        transition: transform ease-in-out .4s, opacity ease-out .3s .2s;
      }
    }

    &.ng-hide-add,
    &.ng-hide-remove.ng-hide-remove-active {
      background: rgba(18,18,18,0.7);
      transition: all ease-out .5s;

      .ui-popup {
        opacity: 1;
        transform: scale(1);
        transition: transform ease-in-out .4s, opacity ease-out .3s;
      }
    }
  }

  .ui-popup {
    background: #EEF0E5;
    position: relative;
    height: 80%;
  }

  .ui-content {
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  .ui-close-button {
    position: absolute;
    top: 0;
    left: 100%;
    padding-left: 10px;

    [ftv-svg] {
      cursor: pointer;
    }
  }
}
@media(max-width: 1279px) {
  ftv-popup {
    .ui-close-button {
      padding-top: 10px;
    }

    .ui-popup {
      height: 100%;
      max-width: calc(100% - 30px);
    }

    .ui-overlay {
      &.ng-hide-add.ng-hide-add-active,
      &.ng-hide-remove {
        .ui-popup {
          transform: translateX(-50%);
        }
      }

      &.ng-hide-add,
      &.ng-hide-remove.ng-hide-remove-active {
        .ui-popup {
          transform: translateX(0);
        }
      }
    }
  }
}
@media(max-width: 959px) {
  ftv-popup {
    .ui-content {
      overflow: auto;
    }
  }
}
