.distress-message-toast {
  top: 17px!important;
  z-index: 9999;
}
.distress-message-toast .md-toast-content{
  background-color: #ebf4f7 !important;
  color: #314550 !important;
  border-left: 10px solid #a2ccd9 !important;
  padding: 10px !important;
  .md-button {
    background-color: #d76713 !important;
  }
}

ftv-preview {
  // position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  .distressed-notification{
    position: absolute;
    background-color: #ebf4f7;
    border-left: 10px solid #a2ccd9;
    padding: 10px 20px;
    margin-top: 0;
    font-size: 15px;
    color: #314550;
    margin: 25px;
    z-index: 4;
  }
  .distressed-notification .close-btn {
    position: absolute;
    right: 6px;
    top: 6px;
    color: #6b6363;
  }


  .showsamples {
    background-color: #959d93;
  }
  .hidesamples {
    background-color: transparent !important;
  }

  #get-samples-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 31;
    background: transparent;
    display: flex;
    flex-direction: row;
    ftv-get-samples-on-preview {
      display: flex;
      flex: 0 1 auto;
      margin: auto;
      #get-samples {
        background-color: transparent;
        .close-chevron.hide-on-open {
          border-radius: 15px;
        }
        .close-chevron {
          top: 7px;
          z-index: 1;
          display: flex;
          width: 138px;
          background-color: #959d93;
          border-radius: 15px 13px 0 0;
          padding: 9px 15px 0 22px;
          align-self: center;

          div {
            width: 100%;
          }
          md-icon {
            margin: 0;
            position: relative;
            top: -2px;
          }
        }
        .close-chevron.is-hidden {
          position: fixed;
          height: 25px;
          bottom: -19px;
          top: 89vh;
          border-radius: 15px;
        }
        .sample-body {
          z-index: 2;
          border-radius: 10px;
          padding: 10px;
          background-color: #959d93;
          font-size: 20px;
          display: flex;
          flex-direction: column;
          div .refund-message {
            margin: 0 0 0 2px;
            font-size: 13px;
            flex: 1 1 auto;
            justify-content: flex-end;
            line-height: 0px;
            p {
              margin: 0;
            }
          }
          .samples-wrapper {
            .md-button {
              border-radius: 10px;
            }
            .inner-wrapper {
              flex: 0 1 auto;
              .sample-container.disabled {
                opacity: 0.5;
              }
              .sample-container {
                flex: 1 1 auto;
                .sample-image-border {
                  padding: 5px;
                  border: 1px solid darkgray;
                  background-color: white;
                  border-radius: 10px;
                  width: 111px;
                  .sample-img {
                    height: 6vh;
                    display: flex;
                    margin: 0 auto;
                  }
                }
                .sample-image-info {
                  width: 100%;
                  .sample-image-info-2 {
                    justify-content: center;
                  }
                  md-checkbox {
                    margin-bottom: 0;
                  }
                  md-checkbox.md-default-theme.md-checked .md-icon,
                  md-checkbox.md-checked .md-icon {
                    background-color: transparent;
                    border: 1px solid white;
                    border-radius: 4px;
                  }
                  md-checkbox.md-default-theme:not(.md-checked) .md-icon,
                  md-checkbox:not(.md-checked) .md-icon {
                    border-color: white;
                    border-radius: 4px;
                  }
                  .md-label {
                    margin-left: 20px;
                  }
                  .sample-name {
                    font-weight: 500;
                    font-size: 13px;
                    font-size: 13px;
                    max-width: 77px;
                    margin: 0 2px 0 2px;
                    flex: 0 1 auto;
                    align-self: center;
                    justify-self: flex-start;
                    max-height: 28px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                  .price {
                    font-size: 20px;
                    font-weight: 600;
                  }
                }
              }
            }
            .math-plus {
              font-size: 34px;
              color: #4a554c;
              padding: 0 10px;
              flex: 1 1 auto;
              padding-bottom: 2vh;
            }
            .sample-total-container {
              flex: 1 1 auto;
              margin-top: 24px;
              .sample-total-wrapper {
                .math-equal {
                  font-size: 35px;
                  color: #4a554c;
                }
                .sample-total {
                  font-size: 35px;
                  padding-left: 10px;
                }
              }
            }
          }
        }
      }

      #get-samples samples-wrapper .inner-wrapper .sample-images.disabled {
        opacity: 0.6;
        /* background-color: #9e9e9e !important; */
      }
      #get-samples .strike-through {
        text-decoration: none;
      }

      #get-samples samples-wrapper .inner-wrapper .sample-images {
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
        display: block;
        background-color: #eaeaea;
        padding: 0;
        margin: 0;
        margin: 10px;
        font-weight: 700;
        /* border: 2px solid lightgrey; */
      }
    }
  }

  @media (min-width: 600px) and (max-width: 959px) {
    #get-samples-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 31;
      background: transparent;
      display: flex;
      flex-direction: row;
      height: initial;
      ftv-get-samples-on-preview {
        display: flex;
        flex: 0 1 auto;
        margin: 0 auto;
        #get-samples {
          background-color: transparent;
          .close-chevron {
            top: 7px;
            z-index: 1;
            display: flex;
            width: 138px;
            background-color: #959d93;
            border-radius: 15px 13px 0 0;
            padding: 9px 15px 0 22px;
            align-self: center;
            div {
              width: 100%;
            }
            md-icon {
              margin: 0;
            }
          }
          .close-chevron.hide-on-open {
            position: fixed;
            height: 25px;
            bottom: -19px;
            top: 89vh;
            border-radius: 15px;
          }
          .sample-body {
            z-index: 2;
            border-radius: 10px;
            padding: 10px;
            background-color: #959d93;
            font-size: 20px;
            div .refund-message {
              margin: 0 0 0 2px;
              font-size: 13px;
              flex: 1 1 auto;
              justify-content: flex-end;
              line-height: 13px;
              p {
                margin: 0;
              }
            }
            .samples-wrapper {
              flex-wrap: wrap;
              margin-top: 11px;
              .inner-wrapper {
                .sample-container {
                  .sample-image-border {
                    padding: 5px;
                    border: 1px solid darkgray;
                    background-color: white;
                    border-radius: 10px;
                    width: initial;
                    .sample-img {
                      height: 6vh;
                      display: flex;
                      margin: 0 auto;
                    }
                  }
                  .sample-image-info {
                    width: 100%;
                    .sample-name {
                      font-weight: 500;
                      font-size: 13px;
                      font-size: 13px;
                      max-width: 77px;
                      margin: 0 2px 0 2px;
                      flex: 0 1 auto;
                      align-self: center;
                      justify-self: flex-start;
                    }
                    .price {
                      font-size: 20px;
                      font-weight: 600;
                    }
                  }
                }
              }
              .math-plus {
                font-size: 34px;
                color: #4a554c;
                padding: 0;
                flex: 1 1 auto;
                padding-bottom: 2vh;
              }
              .sample-total-container {
                flex: 1 1 auto;
                margin-top: 24px;
                justify-content: center;
                .sample-total-wrapper {
                  button {
                    align-self: center;
                  }
                  .math-equal {
                    font-size: 35px;
                    color: #4a554c;
                  }
                  .sample-total {
                    font-size: 35px;
                    padding-left: 10px;
                  }
                }
              }
            }
          }
        }

        #get-samples samples-wrapper .inner-wrapper .sample-images.disabled {
          opacity: 0.6;
          /* background-color: #9e9e9e !important; */
        }
        #get-samples .strike-through {
          text-decoration: none;
        }

        #get-samples samples-wrapper .inner-wrapper .sample-images {
          min-width: -webkit-fit-content;
          min-width: -moz-fit-content;
          min-width: fit-content;
          display: block;
          background-color: #eaeaea;
          padding: 0;
          margin: 0;
          margin: 10px;
          font-weight: 700;
          /* border: 2px solid lightgrey; */
        }
      }
    }
  }

  .glass-scalable-wrapper {
    position: relative;
  }
  .preview-placeholder {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    // object-fit: cover;
    justify-self: center;
    align-self: center;
    min-width: 0;
    min-height: 0;
  }

  .image {
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image img {
    height: 100%;
    object-fit: contain;
    min-width: 0;
    .zoomed {
      max-width: 80%;
    }
  }

  .cut-glass-preview {
    position: absolute;
    width: 100%;
    height: 100%;

    > div {
      background: linear-gradient(
        -28deg,
        #a6abaf 0%,
        #a8adb0 4%,
        #bfbfbf 45%,
        #c9c9c9 45%,
        #e1e0e0 64%,
        #d7d8d8 80%,
        #c6c7c7 80%,
        #a6abaf 100%
      );
      box-shadow: 0 15px 60px 0 rgba(0, 0, 0, 0.7);
      transition: width ease-in-out 0.3s;
      position: relative;

      > div {
        transition: margin ease-in-out 0.3s;
      }
    }

    .value {
      padding: 2px;
      white-space: nowrap;
      font-size: 5px;
    }

    .glass-width {
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      // padding-bottom: 20px;

      .left,
      .right {
        height: 19px;
        position: relative;

        &:after,
        &:after {
          position: absolute;
          border-bottom: solid 0.1em #000;
          padding-top: 9px;
          content: "";
          left: 0;
          right: 0;
        }
      }

      .left {
        border-left: solid 0.1em #000;
      }

      .right {
        border-right: solid 0.1em #000;
      }
    }

    .glass-height {
      position: absolute;
      left: 100%;
      top: 0;
      bottom: 0;
      // padding-left: 20px;

      .bottom,
      .top {
        width: 19px;
        position: relative;

        &:after,
        &:after {
          position: absolute;
          border-right: solid 1px #000;
          padding-left: 9px;
          content: "";
          top: 0;
          bottom: 0;
        }
      }

      .top {
        border-top: solid 1px #000;
      }

      .bottom {
        border-bottom: solid 1px #000;
      }
    }
  }
}

@media (max-width: 599px) {
  ftv-preview {
    .preview-placeholder {
      max-width: 77%;
      display: block;
      margin: 0 auto;
    }
    .cut-glass-preview {
      .glass-width {
        padding-bottom: 0;
      }
      .value {
        font-size: 12px;
      }
    }
  }
}
@media (min-width: 600px) and (max-width: 959px) {
  ftv-preview {
    .cut-glass-preview .value {
      font-size: 10px;
    }
  }
}
/*
@media(max-width: 499px) {
  ftv-preview {
    .preview-placeholder{
      max-width: 77%;
      display: block;
      margin: 0 auto;
    }
    .cut-glass-preview .glass-width {
      padding-bottom:0;
    }
  }
  .cut-glass-preview {
    font-size: 15px;
  }
}
@media(min-width: 500px) and (max-width: 599px) {
  ftv-preview {
    .preview-placeholder{
      max-width: 77%;
      display: block;
      margin: 0 auto;
    }
    .cut-glass-preview .glass-width {
      padding-bottom:0;
    }
  }
  .cut-glass-preview {
    font-size: 12px;
  }
}
@media(min-width: 600px) and (max-width: 959px) {
  ftv-preview {
    .cut-glass-preview {
      font-size:12px;
      .value {
        padding: 15px;
        white-space: nowrap;
      }

      .glass-width {
        .left,
        .right {
          height: 11px;
          &:after,
          &:after {
            padding-top: 5px;
          }
        }
      }

      .glass-height {
        .bottom,
        .top {
          width: 11px;
          &:after,
          &:after {
            padding-left: 5px;
          }
        }
      }
    }
  }
}

@media (min-width: 960px) {
  ftv-preview {
    .cut-glass-preview {
      font-size:2px;
      .value {
        padding: 4px;
        white-space: nowrap;
      }

      .glass-width {
        .left,
        .right {
          height: 7px;
          &:after,
          &:after {
            padding-top: 3px;
          }
        }
      }

      .glass-height {
        .bottom,
        .top {
          width: 7px;

          &:after,
          &:after {
            padding-left: 3px;
          }
        }
      }
    }
  }
}
*/
