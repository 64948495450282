ftv-mini-cart-component {
  display: inline-block;
  height: 0;
  width: 0;
  .overlay-background {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #464242c7;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1999;
  }
  .mini-cart-wrapper {
    .btn-close {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 99999999;
      padding: 10px;
      background-color: #c15f14;
      color: white;
      border-radius: 25px;
      line-height: 12px;
    }

    .mini-cart-body {
      &.ng-hide {
        right: -90vw;
      }

      transition: all linear .5s;
      position: absolute;
      top: 0px;
      right: 0;
      width: 90vw;
      height: 100vh;
      z-index: 9998;
      background-color: #eae8df;
      max-width: 550px;
      overflow-y: auto;
      overflow-y: scroll;


      h1 {
        font-size: 26px;
        border-bottom: 1px solid darkgray;
        background-color: #eae8df;
        padding: 15px;
        margin: 0;
      }
      .note {
        padding: 25px;
      }
      .no-items {
        font-size: 20px;
        padding: 20px;
        margin: 0 auto;
      }
      .cart-item {
        border-bottom: 1px solid #f2f2;
        padding: 15px;

        .image img {
          width: 24vw;
          max-width: 200px;
          height: auto;
          padding: 20px;
        }
        a{
          text-decoration: none;
        }
        .product-info-wrapper {
          h3 a,
          h3 a:visited {
            color: #333333;
          }
          .sku {
            font-size: 12px;
            color: #979698;
            margin: 0 0 6px 0;
          }
          h3,
          .h3 {
            font-family: Poppins, sans-serif;
            font-weight: 500;
            font-size: 27px;
            text-transform: none;
            line-height: 1.5;
            color: #333333;
            display: block;
            letter-spacing: 0px;
            text-rendering: optimizeLegibility;
            margin: 0 auto 15px 0;
          }
          p {
            margin: 0;
            font-style: normal;
            line-height: 1.6em;
            .meta {
              .label {
                color: #8c8b8b;
                font-size: 13px;
              }
            }
          }
          .price {
            font-size: 20px;
            margin-bottom: 10px;
            span {
              font-size: 12px;
              color: #979698;
            }
          }
          .quantity-wrapper {
            flex: 0 0 auto;
            .plus,
            .minus,
            .quantity {
              border: 1px solid lightgray;
              padding: 5px 10px;
              font-size: 21px;
            }
            .quantity {
              background-color: #f2f2f2;
            }
            .plus,
            .minus {
              cursor: pointer;
              background-color: #e2e2e2;
            }
          }
        }
      }
    }
  }
  .cart-summary {
    padding: 20px;
    .comment-line {
      margin: 10px;
    }
    .subtotal {
      display: flex;
      justify-content: space-between;
      font-size: 24px;
      margin: 20px 0;
      padding: 0 10px;
    }
    .md-button {
      background-color: #928461;
      color: white;
      max-width: 90vw;
      &:not([disabled]):hover {
        background-color: rgb(215, 103, 19);
      }
    }
  }
  @media (max-width: 599px) {
  }
  @media only screen and (max-width: 798px) {
    h3,
    .h3 {
      font-size: 21px !important;
    }
  }
}
