ftv-cart-button {

  .md-button.md-icon-button {
    border-radius: 0;
  }

  .badge {
    background: red;
    text-align: center;
    width: 15px;
    height: 15px;
    line-height: 15px;
    font-size: 8px;
    color: #fff;
    border-radius: 10px;
    position: absolute;
    bottom: 2px;
    left: 2px;

    &.ng-enter {
      transition: all 0.5s cubic-bezier(0.54, 2.3, 0.63, 1);
      transform: scale(0, 0)
    }
    &.ng-enter-active {
      transform: scale(1, 1)
    }
    &.ng-leave {
      transition: 0.2s;
    }
    &.ng-leave-active {
      transform: scale(0, 0);
      opacity: 0;
    }
  }
}
