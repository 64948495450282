ftv-categorized-scroller {
  display: flex;
  flex-direction: column;
  position: relative;

  .instructions {
    text-align: right;
  }

  .items-container {
    overflow: auto;
  }

  .category-thumb {
    margin: 10px 15px 10px 0;
    max-width: 40%;
  }

  .top-bar {
    overflow: hidden;
    min-height: 48px;
    height: 48px;

    &.ng-hide-add,
    &.ng-hide-remove {
      transition: height .3s;
    }

    &.ng-hide {
      height: 0;
    }
  }

  .scroll-indicator {
    position: absolute;
    bottom: 0;
    padding: 5px 25px;
    background: #D76713;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    left: 50%;
    margin-left: -10px;

    svg path {
      fill: #fff;
    }
  }
}
@media(max-width: 599px) {
  ftv-categorized-scroller {
    [hide-xs] {
      display: none;
    }
  }
}
@media(max-width: 959px) {
  ftv-categorized-scroller {
    .full-categories {
      display: flex;
      flex-direction: column;
      align-content: stretch;
      justify-content: center;

      a {
        height: auto;
        flex: 1 1 40%;
      }
    }
  }
}
@media(min-width: 600px) and (max-width: 959px) {
  ftv-categorized-scroller {
    [hide-sm] {
      display: none;
    }
  }
}
@media(min-width: 960px) {
  ftv-categorized-scroller {
    .full-categories {
      display: flex;
      align-content: center;
      justify-content: center;

      a {
        max-width: 40%;
        flex: 1 1 40%;
      }
    }
  }
}
@media(max-width: 1279px) and (min-width: 960px) {
  ftv-categorized-scroller {
    .full-categories {
      a {
        height: 150px;
      }
    }
  }
}
@media(min-width: 1280px) {
  ftv-categorized-scroller {
    .full-categories {
      a {
        height: 200px;
      }
    }
  }
}
