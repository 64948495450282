ftv-mobile-menu {
  .mini-cart-icon {
    z-index: 1000;
    display:flex;
    // background-color: #ffffff;
    // padding: 0 0 2px 2px;
    // border-radius: 8px;
    cursor: pointer;
    margin-right: 5px; // so the close btn in filters don't overlap

  }
  .top-banner{
    width: 100vw;
    background-color: #928461;
    color: white;
    min-height: 47px;
    font-size: .9em;
    text-align: center;
    button {
      background-color: #ee4a24;
      color: white;
      border: 0;
      padding: 5px 15px;
      min-width: 95px;
      margin-left: 10px;
    }
  }
  .new-button{
    padding: 0 5px 0 5px;
    color: #6d6e69;
    border-right: 1px solid #6d6e69;
    margin-right: 11px;
    border-left: 1px solid #6d6e69;
  }
  .counter {
    color: white;
    background-color:rgb(215,103,19);
    border-radius: 50%;
    width: 17px;
    height: 17px;
    padding: 0px;
    border: 2px solid rgb(215,103,19);
    text-align: center;
    font: 13px Arial, sans-serif;
    line-height: 17px;
    position: relative;
    top: -3px;
    right: 1px;
  }

  .btn-view-my-designs{
    display: flex;
    line-height: normal;
    width:72px;
    color: #6d6e69;
    .counter {
      top: -1px;
      right: -3px;
    }

}

.phone-icon{
  padding: 0 8px;
  width: 21px;
  border-left: 1px solid #6d6e69;
  border-right: 1px solid #6d6e69;
  margin: 0 4px;
}

  .secondary-mobile-menu{
    padding: 0;
    margin: 0;
    max-height: 21px;
    flex: 1 1 auto;
    align-content: flex-start;
    justify-content: flex-start;
    flex-grow: 0;
    background-color: #eef0e5;
    line-height: 10px;
    position: relative;
    min-height: 0;
    top: 0px;
    z-index: 999;
    .top-link-container{
      margin: 0 15px;
      a {
        color:darkslategray;
      }
    }
  }
  @media (max-width: 375px) {
    .btn-view-my-designs .view-label{
          display: none !important;
    }
  }
  @media(max-width: 599px) {
    #btn-save-design{
      display: none;
    }
  }
  .mobile-logo{
    flex: 1 1 auto;
    max-width:105px;
    svg {
      max-width: 100px;
      padding-top: 10px;
    }
  }
  > div {
    height: 45px;
    min-height: 45px;
    max-height: 45px;
    background: #eef0e5;

    a {
      text-decoration: none;
      box-sizing: content-box !important;
    }

    .back-icon {
      fill: rgb(103, 105, 104);
      width: 31px;
      padding: 0 5px 0 2px;
      text-align: center;
    }

    .small-icon {
      padding: 0 14px;
      width: 21px;
      svg {
        width:21px;
      }
    }
  }
}
