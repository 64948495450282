md-sidenav.mirror {
  width: 90%;
  max-width: 90%;
  min-width: 200px;
  overflow: hidden;
  height: 100%;

  .preview-pane {
    .samples-notice {
      color: #999;
      font-style: italic;
    }
  }

  .no-preview {
    text-align: center;
    transition: opacity 0.3s;

    &.ng-hide {
      opacity: 0;
      position: absolute;
    }
  }
}
