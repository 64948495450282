.print {
  min-width: 0;
  width: 100vw;
  height: 100vh;
  md-content{
    overflow-x: scroll;
    min-height: 0;
  }
  .buttons {
    padding: 20px;

    md-checkbox {
      margin: 0 20px 0 0;
    }
  }

  .page {
    background: #fff;
    margin: 20px;
    padding: 20px;
    box-shadow: 0 0 15px 0 black;

    .header {
      margin-bottom: 30px;

      .logo {
        height: 100px;
      }

      .phone {
        text-align: right;
        font-size: 1.2em;
      }
    }

    .config-id {
      text-align: center;
    }

    table.print-table {
      width: 100%;

      td {
        vertical-align: top;
      }

      th {
        text-align: left;

        h4 {
          border-bottom: solid 1px black;
          margin: .8em 0;
        }
      }
    }

    .preview {
      position: relative;
      // padding-bottom: 70%;

      .name {
        text-align: center;
        margin-top: 10px;
      }

      img {
       // position: absolute;
        margin-top: 2%;
        left: 0;
        height: auto !important;
        width: 100%;
      }

      .legend {
        position: absolute;
        font-size: .7em;
        text-align: center;
        bottom: 10px;
        left: 0;
        right: 0;
      }
    }
  }
}
@media print {
  body {
    all: unset;
  }
  .print {
    font-size: .8em;
    display:block;
    overflow: visible;
    background-color:#fff;

    .page {
      background: #fff;
      margin: 0;
      padding: 0;
      box-shadow: none;
      overflow:visible;
      page-break-before: always;
      .preview {
        -webkit-print-color-adjust: exact;
      }
    }
  }
}
