body {
  overflow: hidden;
}

#layout-container, body {
  background-color: #fff;
}

.md-dialog-container {
  z-index: 1001;
}

md-sidenav {
  z-index: 1000;
}

right-bar md-sidenav, .right-bar md-sidenav {
  min-width: 304px;
  width: 85vw;
  max-width: 650px;
}

ftv-live-chat {
  height: 40px;
}

.watermark {
  padding: 10px;

  span {
    font-size: 10px;
    color: #bbb;
  }

  a {
    font-size: 16px;
    color: #bbb;
    text-decoration: none;
  }

  a:visited {
    color: #bbb;
  }
}
