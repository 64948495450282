ftv-overlay-tab {
  position: fixed;
  left: 0;
  bottom: calc((100vh - 188px) / 2);
  background: #d76713;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  white-space: nowrap;
  padding: 5px 20px;
  color: #fff;
  font-weight: 700;
  transform-origin: top left;
  transform: rotate(270deg) translateX(-50%);
  box-shadow: 0 0 10px rgba(0,0,0,0.4);
  z-index: 10;

  a {
    color: #fff;
    text-decoration: none;
  }

  ftv-svg {
    display: inline-block;
    margin-left: 8px;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}
@media(max-width: 960px) {
  ftv-overlay-tab {
    display: none;
  }
}
