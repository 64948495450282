ftv-cut-glass {
  md-autocomplete {
    min-width: 0;

    md-input-container {
      margin: 0;
    }

    input.md-input {
      background-image: url("../images/icons/arrow_down.svg");
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: right;
    }
  }

  .glass-buttons button {
    width: 100%;
    margin: 0;
  }
}

md-sidenav.cut-glass {
  width: 90%;
  max-width: 90%;
  min-width: 200px;
  overflow: hidden;
  height: 100%;

  .preview-pane {
    .samples-notice {
      color: #999;
      font-style: italic;
    }
  }

  .no-preview {
    text-align: center;
    transition: opacity .3s;

    &.ng-hide {
      opacity: 0;
      position: absolute;
    }
  }
}
