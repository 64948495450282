ftv-get-samples {
  display: absolute;
  height: 0;
  width: 0;
}

#get-samples {
  min-height: 0 !important;
  min-width: unset !important;
  max-width: 1000px;
  padding: 0;
  margin: 0;
  background-color: #e9e9df;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color:white;
  .samples-selection{
    margin: 6px;
  }
  .strike-through {
    text-decoration: line-through;
  }

    .get-sample-title {
      h1 {
        margin-bottom: 0;
        font-size: 22px;
      }
      p {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.01em;
        line-height: 29px;
        margin-bottom: 0;
      }
    }
    .sample-image-wrapper {
      flex-direction: row;

      .inner-wrapper{
        flex: 0 1 auto;
      .sample-images {
        min-width: fit-content;
        display: block;
        background-color: white;
        padding: 5px;
        margin: 10px;
        font-weight: 600;
        box-shadow: 1px 0px 13px 3px #b3b3b3;
        &.disabled {
          background-color: darkgray;
        }
        .sample-img {
          height: 71px;
          border-radius: 3px;
          width: auto;
          align-self: center;
        }
      }
    }
    .sample-total-wrapper {
      justify-content: center;
      align-items: center;
      .total {
        font-size: 30px;
        margin:0 20px 0 0;
      }
      .sample-total {
        font-size: 30px;
        margin: 0;
        font-weight: 600;
        align-self: center;
      }
      .math_sign{
        font-size: 35px;
      }
    }
    .math_sign {
      font-size: 35px;
      color: black;
      margin: 0 auto;
      font-weight: 600;
      color: #564f4f;
      align-self: center;
    }
    .add-samples-btn {
      flex: initial;
    }
  }
}

@media (max-width: 600px) {
  #get-samples-dialog {
    .sample-image-wrapper {
      display: block;
      .inner-wrapper {
        justify-content: space-between !important;
        cursor: pointer;

        .sample-images{
          padding: 5px 0;
          .sample-name{
            text-align: center;
          }
          .sample-img {
            border-radius: 3px;
            margin: 25px;
            width: 41vw;
            align-self: center;
            height: auto;
          }
        }
      }

      .select-samples {
        margin-top: 0;
      }
    }
  }
}

@media (max-width: 725px) {
  #get-samples-dialog {
    .inner-wrapper {
      justify-content: space-between !important;
    }
  }
}
