ftv-mini-loading-indicator {
  position: absolute;
  width: 100%;
  height: 100%;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,.2);
    z-index: 90000;
  }

  .popup {
    width: 185px;
    height: 83px;
    border-radius: 5px;
    background-color: transparent;
    color: white;
  }
}
