ftv-pane-header {
  width: 100%;

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    > .fill {
      flex: 1 1;
      .selected-option-description {
        color:black;
      }
      .unselected-option-description{
        color: rgb(171,81,15);
      }
    }    
  }

  .empty-circle {
    min-width: 30px;
    height: 30px;
    margin-right: 15px;
    border-radius: (30px / 2);
    border: solid 1px #d76713;
    box-sizing: border-box;
  }

  .green-check {
    display: flex;
    min-width: 30px;
    height: 30px;
    margin-right: 15px;
    color: white;
    flex-direction: row;
    background-color: #219653;
    border-radius: (30px / 2);

    align-items: center;
    justify-content: center;

    span {
      width: 16px;
      height: 13px;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='13'><path fill='#fff' fill-rule='nonzero' d='M14.541.194a.66.66 0 0 0-.93 0L5.353 8.45a.66.66 0 0 1-.93 0L2.39 6.417a.66.66 0 0 0-.931 0L.212 7.665a.66.66 0 0 0 0 .93l4.211 4.211a.66.66 0 0 0 .931 0L15.788 2.372a.66.66 0 0 0 0-.931L14.541.194z'/></svg>");
    }
  }
}
