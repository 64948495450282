nav-bar {
  .logo {
    min-width: 70px;
    height: 45px;
    margin-right: 15px;
  }
}

@media(max-width: 599px) {
  nav-bar .phone-number {
    text-align: right;
    font-size: 0.8em;
  }
}
