@media screen and (min-width: 1367px) {
  .md-button.md-primary.ftv-button:hover {
    background-color: #d76713 !important;
    color: white;
    border: 2px solid #928461;
    border-radius: 15px;
  }
}

@media (max-width: 959px) {
  md-dialog.md-dialog-fullscreen {
    min-height: 90% !important;
    min-width: 90% !important;
    border-radius: 0;
    min-height: fit-content !important;
    padding: 10px;
  }
}
@media (max-width: 600px) {
  .choose-text{
    display:none;
  }
  .tv-model-input{
    margin-left: 0 !important;
  }
  .tv-model-text{
    padding: 0  !important;
  }
  .tv-model md-checkbox {
    margin-bottom: 0;
    margin-left: 0;
    margin-top: 10px;
  }
  .tv-model .size-selector{
      margin-left: 0 !important;
  }

  .tv-model .custom-size-selector{
    margin-left: 0 !important;
  }
  .tv-model .md-button.md-primary.ftv-button,
  .tv-model .md-button.md-primary.selected.ftv-button {
    border-radius: 10px;
    font-weight: 600;
    border: 1px solid #d76713;
    background-color: white !important;
    border: 1px solid #ee4a24;
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 86px !important;
    height: 62px;
    font-size: 22px;
    margin: 2px;
  }
  .tv-model .md-title {
    font-size: 14px;
    padding-bottom: 0;
    margin-bottom: 0;
    padding-top: 0;
  }
  .or-you-can-enter.layout-fill.layout-padding.layout-align-center-center.layout-row.flex {
    padding: 0;
    margin: 0;
  }
  .layout-fill.layout-padding.layout-align-center-start.layout-row.flex {
    margin: 0;
    padding: 0;
  }
  .btn-other {
    margin: 0;
  }
}

.checkbox_container {
  margin-left: 20px;
}
.tv-model {
  md-checkbox {
    margin-bottom: 0px;
    margin-left: 20px;

    .md-label {
      font-size: 13px;
      margin: 0 0 0 29px;
    }
  }
  .md-body-1 {
    font-weight: 500;
  }
  md-dialog.md-default-theme,
  md-dialog {
    border-radius: 18px !important;
  }
  .size-selector {
    margin-left: 20px;
  }

  md-toolbar.md-default-theme:not(.md-menu-toolbar),
  md-toolbar:not(.md-menu-toolbar) {
    background-color: white !important;
    color: #314550 !important;
  }

  @media (min-width: 500px) and (max-width: 600px) {
    .tv-model {
      .size-selector {
        flex-wrap: nowrap !important;
        overflow-x: scroll;
        margin-left: 0 !important;
      }
      .custom-size-selector {
        margin-left: 0 !important;
      }
    }
    margin: 2px;
  }



  .tv-model-input{
    margin-left: 30px;
  }
  .tv-model-text{
    padding: 8px;
  }
  .disable-other {
    color: lightgray;
    label {
      color: lightgray;
    }
    input {
      border: solid 1px lightgray !important;
    }
    div {
      color: lightgray;
    }
  }

  .md-toolbar-tools {
    background-color: #eae8df !important;
    color: #314550 !important;
    background-image: url(assets/configurator/tv-icon.png);
    background-repeat: no-repeat;
    padding-left: 32px;
    background-position: left;
    font-weight: 600;
    margin-left: 17px;
    border-bottom: 5px solid #979698;
  }
  .md-title {
    font-size: 18px;
  }

  .ftv-button {
    border: none;
    background-color: white !important;
  }

  .md-button.md-primary.ftv-button {
    background-color: white;
    color: #231f20;
    padding: 12px 1px;
    font-weight: 400;
    font-size: 25px;
    background-color: white !important;
    border: 1px solid lightgray;
    border-radius: 15px;
    width: 94px;
    height: 94px;
  }

  .md-button.md-primary.selected.ftv-button {
    border-radius: 10px;
    font-weight: 600;
    border: 1px solid rgb(215, 103, 19);
    background-color: white !important;
    color: #231f20;
    border: 1px solid #ee4a24;
    position: relative;
    display: inline-flex;
    width: 94px;
    height: 94px;
    &:after {
      content: "✔";
      position: absolute;
      bottom: 19px;
      right: 5px;
      color: white;
      height: 10px;
      width: 10px;
      font-size: 17px;
    }

    &:before {
      content: "";
      border-style: solid;
      color: red;
      border-width: 0 0 30px 30px;
      border-color: transparent transparent #ee4a24;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .caret {
    position: absolute;
    bottom: 31px;
    right: 23px;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    color: orange;
  }

  form[name="tvSizeForm"] {
    .head .md-caption {
      margin-left: 5px;
    }

    .standard-size-btn {
      width: 20%;
    }

    .warning {
      color: red;
      text-align: center;
    }
  }

  .loading-indicator {
    min-height: 300px;
  }

  input {
    border: solid 1px rgb(215, 103, 19);
    background: #fff;
    border-radius: 2px;
    padding: 5px;
  }
}
