
add-to-cart {
  &.summary-bar-cart{
    .alert-unselected{
      background-color: transparent;
    }
  }
  display: flex;
  // padding-bottom: 15px;
  button.md-raised.md-primary.add-to-cart.md-button.ng-scope.md-default-theme.md-ink-ripple {
    flex: 1 1 50%;
  }
  md-icon.alert-unselected.ng-scope.md-default-theme {
    margin: 0;
    padding: 0;
  }
  svg {
    fill: #fff;
  }
  .small-button{
    margin: 5px 2px 0 0;
    border-radius: 3px;
  }
  .message-overlay{
    font-size: 12px;
    padding: 0;
    margin: 0;
    color: #d76713;
    position: absolute;
    top: -17px;
    width: 283px;
    right: 0;
  }
}

@media (max-width: 599px) {
  add-to-cart.summary {
    button.md-raised.md-primary.add-to-cart.md-button.ng-scope.md-default-theme.md-ink-ripple {
      border-radius: 0;
      }
  }
  add-to-cart {
    .alert-unselected{
      position: relative;
      top: -3px;
      svg{
        height: 15px;
      }
    }
    button.md-raised.md-primary.add-to-cart.md-button.ng-scope.md-default-theme.md-ink-ripple {
    flex: 1 1 50%;
    padding: 0;
    margin: 0;
    border-radius: 2px;
    }
    button.md-raised.md-primary.add-to-cart.md-button {
      margin-left: 2px;
      md-icon {
        margin: auto 2px;
      }
    }
    button.md-primary.btn-save-design.md-button {
      font: size 12px;
    }
    .mobile-message{
      line-height: 12px;
      position: absolute;
      top: 35px;
      width: 374px;
      left: -75px;
      padding-top: 3px;
      max-width: 309px;
      color: white;
    }
    md-icon.alert-unselected.ng-scope.md-default-theme svg {
      fill: #ff9800;
    }
  }
}
