ftv-frame-type-tile,
ftv-external-link-type-tile,
ftv-help-tile,
ftv-quote-tile {

  height: 100%;
  width: 100%;
  z-index: 1;

  .non-hover-title{
    margin-bottom: 29px;
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    position: absolute;
    bottom: 0;
    padding-left: 30px;
    max-width: 75%;
  }
  > div {
    padding: 30px;
    height: 100%;

    .tile-headline {
      margin-bottom: 17px;
      font-size: 25px;
      font-weight: normal;
      color: #fff;
    }

    .tile-subheadline {
      font-size: 16px;
      line-height: 21px;
      color: #fff;
    }

    .tile-text {
      font-size: 14px;
      font-weight: normal;
      line-height: 21px;
      color: rgba(254,255,255,0.88);
    }

    .quote-text {
      font-weight: bold;
      font-size: 21px;
      line-height: 26px;
      color: #fff;
    }

    .quote-author {
      font-size: 14px;
      line-height: 18px;
      color: rgba(255,255,255,0.6);
      margin-top: 30px;
    }

    .title {
      margin-bottom: .4rem;
      font-size: 24px;
      font-weight: bold;
      color: #fff;
    }

    .description {
      font-size: 16px;
      font-weight: normal;
      line-height: 21px;
      color: rgba(254,255,255,0.88);
      margin: 2vh 0;
      // height: 60px;
    }
    .price-and-time{
      background-color: #354555;
      padding: 0 10px;
      color:white;

      .priced-from {
        font-size: 15px;
        border-bottom: 1px solid white;
        padding: 10px;
        align-items: center;
        justify-content: center;
      }
      .price {
        font-size: 22px;
        .units{
          font-size:13px;
        }
      }
      .production-time{
        font-size: 12px;
        padding:10px;
        align-items: center;
        justify-content: center;
        .time{
          font-size:12px;
        }
      }

    }
    .action {
      padding: 7px 0;
      margin-top: 10px;
      font-size: 14px;
      color: #9F8D5C;
      text-decoration: none;
      border: 1px solid #9F8D5C;
      border-radius: 2px;
      text-align: center;
      transition: all .15s ease-in;

      &:hover {
        color:white;
        background-color: #9F8D5C;
        font-weight: 600;
      }
    }
  }
}

ftv-frame-type-tile > div, ftv-external-link-type-tile > div {
  transform: translateY(200%);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition: transform .5s ;

  .action {
    transition: .5s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    opacity: 0;
  }
}

ftv-frame-type-tile:hover, ftv-external-link-type-tile:hover {
  .non-hover-title {
    display: none;
  }
}
md-grid-tile:hover ftv-frame-type-tile > div, md-grid-tile:hover ftv-external-link-type-tile > div {
  transform: translateY(0);
  .title{
    font-size: 26px;
  }
  .action {
    opacity: 1;
  }
}
@media(max-width: 959px) {
  ftv-frame-type-tile, ftv-external-link-type-tile{
    .non-hover-title {
      display: none;
    }
  }

  md-grid-tile ftv-frame-type-tile > div, md-grid-tile ftv-external-link-type-tile > div {
    transform: translateY(0);
    .non-hover-title {
      display: none;
    }
    .action {
      opacity: 1;
    }
  }
}


