md-sidenav.shopping-cart {
  width: 70%;
  max-width: 70%;
  min-width: 300px;
  overflow: hidden;
  height: 100%;

  ftv-cart {
    display: block;
    height: 100%;
  }
}
