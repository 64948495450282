ftv-frame-details {
  height: 100%;
  min-height: 100%;
  display: block;
  position: relative;
  display: flex;
  flex-direction: column;

  .fixed {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: white;
  }

  .preview-container {
    // transition: opacity 0.3s;
    height: auto;
    overflow-y: auto;

    // &.ng-hide {
    //   opacity: 0;
    //   position: absolute;
    // }

    .image-container {
      max-height: 250px;
      height: 100%;

      .thumb {
        max-height: 250px;
      }
    }
  }

  .finish-thumb {
    width: 33%;
    margin: 5px 16px 5px 0;
  }

  .samples-notice {
    color: #999;
    font-style: italic;
  }
}
