ftv-unsaved-notification > div {
  height: 0;
  transition: height .3s;
  background-color: #AB510F;
  overflow: hidden;

  &.visible {
    height: 48px;
  }

  > span {
    color: #fff;
    padding: 0 15px;
  }
}
