.option-pane{
    background-color: white;
    margin-bottom: 10px;
    padding: 10px 5px 0;
    border-bottom: 1px solid #979698;
}

.design-button{
    .md-primary {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        height: 50px;
        // width: 50px;
        background-color: #e9e9df !important;
        color: #b37147 !important;
        line-height: 14px;
        padding: 15px 5px;
    }
}
ftv-art-bar-component.layout-fill.ng-isolate-scope.layout-align-start-start.layout-row {
    flex-shrink: 0;
}

.view-all-btn{
    align-self: flex-end;
    justify-self: center;
}

.prod-option-btn-bar {
    .md-button {
        background-color: #384652;
        border-radius: 20px;
        color: white;
        padding: 0;
        margin: 10px 15px 0;
        line-height: 30px;
        min-height: 20px;
        &:hover{
            background-color: #EE4A24 !important;
        }
    }
}
// this is added to the dom root not as a child in the html
md-tooltip.slider-tooltip {
    background-color: #383e52 !important;
    color: white!important;
    font-size: 12px;
    height: 22px;
    padding: 5px;
    opacity: 1 !important;
}

.selection-bar{
    display: flex;
    position: relative;
    /* width: 100%; */
    overflow-x: auto;
    flex-wrap: nowrap;
    flex-shrink: 0;

    .thumb-slider {
        margin: 0 6px 0 6px;
        display: flex;
        align-items: center;
        position: relative;
        /*width: 100%; */
        margin-right: 10px;
        overflow-x: auto;
        flex-wrap: nowrap;
        flex-shrink: 0;
        scroll-behavior: smooth;
    }

    .scroll-btn {
        z-index: 1;
        height: 74%;
        top: 10%;
        background-color: #39313161;
        position: absolute;
        color: white;
    }
    .scroll-left{
        left: -1px;
    }
    .scroll-right{
        right:0;
        svg {
            width: 69%;
        }
    }

    .thumb-wrapper {
        width: auto;
        display:inline-flex;
        flex: 0 0 auto;
        &.selected{
            width: auto;
            border: 1px solid #ee4a24;
            position: relative;
            display:inline-flex;
            &:after{
                content: "✔";
                position: absolute;
                bottom: 9px;
                right: 2px;
                color: white;
                height: 10px;
                width: 10px;
                font-size: 14px;
            }

            &:before{
                content: "";
                border-style: solid;
                color: red;
                border-width: 0 0 25px 25px;
                border-color: transparent transparent #ee4a24;
                position: absolute;
                bottom: 0;
                right: 0;
            }

        }

        .thumb-img{
            height: 65px;
            width:auto;
            display: flex;
            flex: 0 1 auto;

            &.selected {
                width: auto !important;
                height: 85px !important;
                margin: 1px;
                padding: 0;
            }
        }
        .thumb-img.liner{
            height: 70px;
            width:auto;
            display: flex;
            flex: 0 1 auto;
            border: 1px #ddd solid;
            margin-right:2px;
            &.selected {
                width: auto !important;
                height: 85px !important;
                margin: 1px;
                padding: 0;
            }
        }
        .thumb-img.frame-thumbnail {
            height: 100px  !important;
            margin-right: -15px;
            &.selected {
                height: 135px !important;
                margin-right:0;
            }
        }
        // metro frames are a wide image and need custom spacing
        .thumb-img.frame-thumbnail.frame-m {
            height: 70px!important;
            margin-right: 10px;
            &.selected {
                height: 105px !important;
                margin-right:0;
            }
        }
    }


}

@media(max-width: 599px) {
    .selection-bar{

        .thumb-slider {
            scroll-behavior: smooth;
            margin-right: 0;
        }

        .scroll-right{
            align-self: flex-start;
            justify-self: end;
        }

        .thumb-wrapper {

            &.selected{
                width: auto;
                border: 1px solid #ee4a24;
                position: relative;
                display:inline-flex;
                &:after{
                    content: "✔";
                    position: absolute;
                    bottom: 9px;
                    right: 2px;
                    color: white;
                    height: 10px;
                    width: 10px;
                    font-size: 14px;
                }

                &:before{
                    content: "";
                    border-style: solid;
                    color: red;
                    border-width: 0 0 25px 25px;
                    border-color: transparent transparent #ee4a24;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }

            }

            .thumb-img{
                height: 45px;
                width:auto;
                display: flex;
                flex: 0 1 auto;

                &.selected {
                    width: auto !important;
                    height: 65px !important;
                    margin: 1px;
                    padding: 0;
                }
            }
            .thumb-img.liner{
                height: 55px;
                width:auto;
                display: flex;
                flex: 0 1 auto;
                border: 1px #ddd solid;
                margin-right:2px;
                &.selected {
                    width: auto !important;
                    height: 70px !important;
                    margin: 1px;
                    padding: 0;
                }
            }
            .thumb-img.frame-thumbnail {
                height: 80px  !important;
                margin-right: -15px;
                &.selected {
                    height: 115px !important;
                    margin-right:0;
                }
            }
        }


    }
}