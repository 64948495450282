img.thumb {
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
  object-fit: scale-down;
  /* height: auto!important; */
}

img.list-thumb {
  max-width: 100px;
  width: 100px;
  margin: 10px 20px 10px 0;
  height: auto!important;
  &.small {
    max-width: 70px;
    width: 70px;
  }
}
