#livechat-compact-container,
#livechat-full {
	left: 20px!important;
	right: auto!important;
}

// #livechat-full {
// 	left: 10px!important;
// 	right: auto!important;
// }

// #livechat-compact-container {
//   margin-right: calc(100vw - 100px);
// }

// @media(max-width: 599px) {
//   #livechat-compact-container {
//     margin-right: calc(100vw - 85px);
//     margin-bottom: -10px;
//   }
// }

