.introjs-helperNumberLayer {
  background: #d56724;
  background: linear-gradient(to bottom,#d56724 0,#bc5b20 100%);
  border: 0 none;
  &:after {
    position: absolute;
    top: -2px;
    left: -2px;
    width: 100%;
    height: 100%;
    content: '';
    border: 2px solid rgba(#d56724,.5);
    border-radius: 50%;
    opacity: 0;
    animation: badge-sonar 1.5s infinite;
  }
}

@keyframes badge-sonar {
  0% {
    opacity: 1;
    transform: scale(.9);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}
